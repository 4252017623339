<template lang="pug">
dnt(thirdParty="youtube" thirdPartyName="youtube.com")
	lite-youtube(:videoid="youtubeId" autoload :style="`background-image: url('https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg');`")
		button.lty-playbtn(type="button")
			span.lyt-visually-hidden Play Video
</template>

<script>
import gql from 'graphql-tag';
import dnt from '@dengl/common/components/dnt';
import fields from './fields';

export default {
	name: 'YoutubeEmbed',
	components: { dnt },
	props: fields,
	mounted() {
		if (!window.LiteYTEmbed) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = false;
			script.crossorigin = 'anonymous';
			script.src = '//unpkg.com/lite-youtube-embed@0.2.0/src/lite-yt-embed.js';
			document.body.append(script);
			window.LiteYTEmbed = true;
		}
	},
	fragments: gql`
		fragment YoutubeEmbedDataFragment on Embed {
			data {
				... on EmbedDataYoutube {
					youtubeId
				}
			}
		}
	`
};
</script>

<style lang="sass">

lite-youtube
	background-color: #000
	position: relative
	display: block
	contain: content
	background-position: center center
	background-size: cover
	cursor: pointer

	&::after
		content: ""
		display: block
		padding-bottom: calc(100% / (16 / 9))


	& > iframe
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
		border: 0


	.lty-playbtn
		width: 68px
		height: 48px
		position: absolute
		cursor: pointer
		transform: translate3d(-50%, -50%, 0)
		top: 50%
		left: 50%
		z-index: 1
		background-color: transparent
		background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>')
		filter: grayscale(100%)
		transition: filter .1s cubic-bezier(0, 0, 0.2, 1)
		border: none


	&.lyt-activated
		cursor: unset

		::before, .lty-playbtn
			opacity: 0
			pointer-events: none

	&:hover .lty-playbtn, .lty-playbtn:focus
		filter: none


	.lyt-visually-hidden
		clip: rect(0 0 0 0)
		clip-path: inset(50%)
		height: 1px
		overflow: hidden
		position: absolute
		white-space: nowrap
		width: 1px
</style>
