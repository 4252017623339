<template lang="pug">

div(v-if="mappedWeblogs.length")
	h2 {{$t('page.areas._area:related.weblogs.heading')}}
	div(v-if="display === 'cards'")
		v-row
			v-col(cols="12" md="4" v-for="weblog in mappedWeblogs" :key="weblog.id")
				v-card(flat v-ripple @click="$router.push(localePath( weblog.to))")
					v-img(:aspect-ratio="16/9" v-if="weblog.image" :src="`${weblog.image}?width=320`" :alt="weblog.title")
					v-img(:aspect-ratio="16/9" v-else src="https://static.wintersport.nl/images/16x9.png" :alt="weblog.title")

					optional-nuxt-link.d-block.font-weight-bold.subtitle-1.mt-3(:to="localePath( weblog.to)") {{weblog.title}}
					v-row.caption.grey--text(dense)
						v-col.label.label-sm.shrink(v-if="weblog.label") {{weblog.label}}
						v-col.shrink.text-no-wrap
							nuxt-link(v-if="weblog.author && weblog.category !== 'advertorial'" :to="localePath({name:'profile-slug', params: {slug: weblog.author.slug}})" :title="weblog.author.username") {{weblog.author.username}}
							v-chip.px-1.mr-2.flex-grow-0.flex-shrink-0(label x-small color="grey lighten-2" v-if="weblog.category === 'advertorial'") gesponsord
						v-col.shrink.text-no-wrap(v-if="weblog.displayDate") {{$t('weblog-item:published.at.{date}', {date: $dayjs(weblog.displayDate).format('DD MMMM YYYY')})}}
						v-col.shrink.text-no-wrap(v-if="weblog.replyCount")
							optional-nuxt-link.grey--text(:to="localePath(weblog.to)") {{$tc('page.weblog.message:reply.{count}', weblog.replyCount, {count: weblog.replyCount})}}

	div(v-else-if="display === 'list' || !display")
		v-row(v-for="weblog in mappedWeblogs" :key="weblog.id")
			v-col.shrink(cols="12" md="auto")
				v-img(:aspect-ratio="16/9" v-if="weblog.image" :width="$vuetify.breakpoint.smAndUp ? 180: 'auto'" :src="weblog.image" :alt="weblog.title")
				v-img(:aspect-ratio="16/9" v-else :width="$vuetify.breakpoint.smAndUp ? 180: 'auto'" src="https://static.wintersport.nl/images/16x9.png" :alt="weblog.title")
			v-col.grow
				optional-nuxt-link.d-block.font-weight-bold.subtitle-1(:to="localePath(weblog.to)") {{weblog.title}}
				v-row.caption.grey--text(dense)
					v-col.label.label-sm.shrink(v-if="weblog.label") {{weblog.label}}
					v-col.shrink.text-no-wrap
						nuxt-link(v-if="weblog.author && weblog.category !== 'advertorial'" :to="localePath({name:'profile-slug', params: {slug: weblog.author.slug}})" :title="weblog.author.username") {{weblog.author.username}}
						v-chip.px-1.mr-2.flex-grow-0.flex-shrink-0(label x-small color="grey lighten-2" v-if="weblog.category === 'advertorial'") gesponsord
					v-col.shrink.text-no-wrap(v-if="weblog.displayDate") {{$t('weblog-item:published.at.{date}', {date: $dayjs(weblog.displayDate).format('DD MMMM YYYY')})}}
					v-col.shrink.text-no-wrap(v-if="weblog.replyCount")
						optional-nuxt-link.grey--text(:to="localePath(weblog.to)") {{$tc('page.weblog.message:reply.{count}', weblog.replyCount, {count: weblog.replyCount})}}
				p.text--primary {{weblog.content.introduction}}
	.text-center(v-if="showMoreButton")
		v-btn.mt-6(color="primary" outlined @click="paginatedLimit += parseInt(limit); nextPage()" v-if="!fetched") {{$t('page.areas._area:related.weblogs.button')}}
	v-divider(v-if="showDivider").my-6
</template>

<script>
import gql from 'graphql-tag';

const query = gql`
	query getWeblogs(
		$limit: Int
		$tags: [String]
		$ignoreTags: [String]
		$destinations: [WeblogDestinationInput]
	) {
		weblogsByTag(
			limit: $limit
			tags: $tags
			ignoreTags: $ignoreTags
			destinations: $destinations
		) {
			weblogs {
				id
				slug
				title
				photo
				replyCount
				content {
					introduction(length: 120)
				}
				defaultVideo {
					thumb
				}
				# author {
				# 	id
				# 	username
				# 	slug
				# }
				author: authorProfile {
					id
					username
					slug
				}
				category
				publishDate
				displayDate
				isVideoBlog
			}
		}
	}
`;

export default {
	name: 'WeblogListEmbed',
	props: {
		limit: {
			type: String,
			default: '3'
		},
		tags: {
			type: Array,
			default() {
				return [''];
			}
		},
		ignoreTags: {
			type: Array,
			default() {
				return ['corona'];
			}
		},
		destinations: {
			type: Array,
			default() {
				return [];
			}
		},
		display: {
			type: String,
			default: 'list',
			validation(val) {
				return ['list', 'cards'].contains(val);
			}
		},
		showMoreButton: {
			type: Boolean,
			default: false
		},
		showDivider: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			weblogs: [],
			fetched: false,
			paginatedLimit: 0
		};
	},
	async fetch() {
		this.weblogs = await this.$graphClient
			.query({
				query,
				variables: {
					tags: this.tags || [],
					ignoreTags: this.ignoreTags || [],
					destinations: this.destinations || [],
					limit: parseInt(this.computedLimit) + 1
				}
			})
			.then(({ data }) => {
				return data.weblogsByTag.weblogs;
			});
	},

	computed: {
		computedLimit() {
			return this.paginatedLimit + parseInt(this.limit);
		},
		mappedWeblogs() {
			return this.weblogs
				.filter(weblog => {
					return (
						this.$route.path.split('/').slice(-1)[0] !==
						this.localePath({
							name: weblog.category === 'news' ? 'news-slug' : 'weblog-slug',
							params: { 0: weblog.slug }
						})
							.split('/')
							.slice(-1)[0]
					);
				})
				.map(weblog => {
					const newBlog = this.$lodash.cloneDeep(weblog);

					newBlog.image =
						weblog.isVideoBlog && weblog.defaultVideo
							? weblog.defaultVideo.thumb
							: weblog.photo;
					newBlog.to = {
						name: newBlog.category === 'news' ? 'news-slug' : 'weblog-slug',
						params: { 0: newBlog.slug }
					};

					return newBlog;
				})
				.slice(0, this.computedLimit);
		}
	},
	methods: {
		async nextPage() {
			this.weblogs = await this.$graphClient
				.query({
					query,
					variables: {
						tags: this.tags || [],
						ignoreTags: this.ignoreTags || [],
						destinations: this.destinations || [],
						limit: parseInt(this.computedLimit) + 1
					}
				})
				.then(({ data }) => {
					return data.weblogsByTag.weblogs;
				});
		}
	},
	fragments: gql`
		fragment WeblogListEmbedDataFragment on Embed {
			data {
				... on EmbedDataWeblogList {
					limit
					tags
					destinations {
						id
						name
						type
					}
					display
				}
			}
		}
	`
};
</script>
