import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _00dadf78 = () => interopDefault(import('../pages/offers.vue' /* webpackChunkName: "pages/offers" */))
const _27049610 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2387c2e2 = () => interopDefault(import('../pages/academy.vue' /* webpackChunkName: "pages/academy" */))
const _26d5845c = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _da3ffa10 = () => interopDefault(import('../pages/academy/_level/index.vue' /* webpackChunkName: "pages/academy/_level/index" */))
const _49d94e7d = () => interopDefault(import('../pages/academy/_level/_lesson.vue' /* webpackChunkName: "pages/academy/_level/_lesson" */))
const _58659311 = () => interopDefault(import('../pages/accommodations/index.vue' /* webpackChunkName: "pages/accommodations/index" */))
const _236ef960 = () => interopDefault(import('../pages/benchmark.vue' /* webpackChunkName: "pages/benchmark" */))
const _4ace1cc6 = () => interopDefault(import('../pages/weblog.vue' /* webpackChunkName: "pages/weblog" */))
const _6941e340 = () => interopDefault(import('../pages/weblog/index.vue' /* webpackChunkName: "pages/weblog/index" */))
const _6caf25d0 = () => interopDefault(import('../pages/weblog/_slug.vue' /* webpackChunkName: "pages/weblog/_slug" */))
const _36163d23 = () => interopDefault(import('../pages/club.vue' /* webpackChunkName: "pages/club" */))
const _448e5810 = () => interopDefault(import('../pages/createprofile.vue' /* webpackChunkName: "pages/createprofile" */))
const _1bf864b8 = () => interopDefault(import('../pages/airports/index.vue' /* webpackChunkName: "pages/airports/index" */))
const _0632b104 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _38ea2ba8 = () => interopDefault(import('../pages/forum/rules.vue' /* webpackChunkName: "pages/forum/rules" */))
const _475c4c4b = () => interopDefault(import('../pages/forum/_topic.vue' /* webpackChunkName: "pages/forum/_topic" */))
const _2de01612 = () => interopDefault(import('../pages/forum/_category.vue' /* webpackChunkName: "pages/forum/_category" */))
const _a1715202 = () => interopDefault(import('../pages/gear/index.vue' /* webpackChunkName: "pages/gear/index" */))
const _c487259c = () => interopDefault(import('../pages/jumbo.vue' /* webpackChunkName: "pages/jumbo" */))
const _30b390f3 = () => interopDefault(import('../pages/localdestinations.vue' /* webpackChunkName: "pages/localdestinations" */))
const _14b828ba = () => interopDefault(import('../pages/localdestinations/dryslopes/index.vue' /* webpackChunkName: "pages/localdestinations/dryslopes/index" */))
const _d887050a = () => interopDefault(import('../pages/localdestinations/revolvingslopes/index.vue' /* webpackChunkName: "pages/localdestinations/revolvingslopes/index" */))
const _7d33d778 = () => interopDefault(import('../pages/localdestinations/skihalls/index.vue' /* webpackChunkName: "pages/localdestinations/skihalls/index" */))
const _6a1e5536 = () => interopDefault(import('../pages/localdestinations/index.vue' /* webpackChunkName: "pages/localdestinations/index" */))
const _13018772 = () => interopDefault(import('../pages/localdestinations/dryslopes/_slug.vue' /* webpackChunkName: "pages/localdestinations/dryslopes/_slug" */))
const _dbf4479a = () => interopDefault(import('../pages/localdestinations/revolvingslopes/_slug.vue' /* webpackChunkName: "pages/localdestinations/revolvingslopes/_slug" */))
const _660035a1 = () => interopDefault(import('../pages/localdestinations/skihalls/_country.vue' /* webpackChunkName: "pages/localdestinations/skihalls/_country" */))
const _7b7d3630 = () => interopDefault(import('../pages/localdestinations/skihalls/_slug.vue' /* webpackChunkName: "pages/localdestinations/skihalls/_slug" */))
const _8103241a = () => interopDefault(import('../pages/localdestinations/_discipline.vue' /* webpackChunkName: "pages/localdestinations/_discipline" */))
const _117abf9d = () => interopDefault(import('../pages/localdestinations/_organisation.vue' /* webpackChunkName: "pages/localdestinations/_organisation" */))
const _257caf4c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _551d5500 = () => interopDefault(import('../pages/memberships.vue' /* webpackChunkName: "pages/memberships" */))
const _5e53c8fa = () => interopDefault(import('../pages/memberships/index.vue' /* webpackChunkName: "pages/memberships/index" */))
const _43e5816c = () => interopDefault(import('../pages/memberships/_type.vue' /* webpackChunkName: "pages/memberships/_type" */))
const _3ed0ccc0 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _1a80c343 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _18ca21fb = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _8775a034 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _3f665d30 = () => interopDefault(import('../pages/over-ons.vue' /* webpackChunkName: "pages/over-ons" */))
const _339a0467 = () => interopDefault(import('../pages/panel.vue' /* webpackChunkName: "pages/panel" */))
const _b34492b2 = () => interopDefault(import('../pages/podcast.vue' /* webpackChunkName: "pages/podcast" */))
const _2560f206 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _745eba91 = () => interopDefault(import('../pages/snowalarm.vue' /* webpackChunkName: "pages/snowalarm" */))
const _4b98c2a8 = () => interopDefault(import('../pages/areas/index.vue' /* webpackChunkName: "pages/areas/index" */))
const _5bfa8b44 = () => interopDefault(import('../pages/lessons.vue' /* webpackChunkName: "pages/lessons" */))
const _2c2e041c = () => interopDefault(import('../pages/rentals.vue' /* webpackChunkName: "pages/rentals" */))
const _6ae95856 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _75488651 = () => interopDefault(import('../pages/trips.vue' /* webpackChunkName: "pages/trips" */))
const _9a1d67d8 = () => interopDefault(import('../pages/trips/index.vue' /* webpackChunkName: "pages/trips/index" */))
const _9d8aaa68 = () => interopDefault(import('../pages/trips/_slug.vue' /* webpackChunkName: "pages/trips/_slug" */))
const _3aa0c860 = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _20037e3b = () => interopDefault(import('../pages/verzekering.vue' /* webpackChunkName: "pages/verzekering" */))
const _67c92c7e = () => interopDefault(import('../pages/verzekering/index.vue' /* webpackChunkName: "pages/verzekering/index" */))
const _c1e19e26 = () => interopDefault(import('../pages/verzekering/basis/index.vue' /* webpackChunkName: "pages/verzekering/basis/index" */))
const _5732e523 = () => interopDefault(import('../pages/verzekering/doorlopend/index.vue' /* webpackChunkName: "pages/verzekering/doorlopend/index" */))
const _c8adb7ca = () => interopDefault(import('../pages/verzekering/kortlopend/index.vue' /* webpackChunkName: "pages/verzekering/kortlopend/index" */))
const _3170cf95 = () => interopDefault(import('../pages/verzekering/login.vue' /* webpackChunkName: "pages/verzekering/login" */))
const _efce65fa = () => interopDefault(import('../pages/verzekering/profiel.vue' /* webpackChunkName: "pages/verzekering/profiel" */))
const _62335187 = () => interopDefault(import('../pages/verzekering/reisverzekering.vue' /* webpackChunkName: "pages/verzekering/reisverzekering" */))
const _7f48c74c = () => interopDefault(import('../pages/verzekering/zorgverzekering.vue' /* webpackChunkName: "pages/verzekering/zorgverzekering" */))
const _469afbaa = () => interopDefault(import('../pages/verzekering/basis/thankyou.vue' /* webpackChunkName: "pages/verzekering/basis/thankyou" */))
const _07b8a801 = () => interopDefault(import('../pages/verzekering/basis/calculate.vue' /* webpackChunkName: "pages/verzekering/basis/calculate" */))
const _6cf95898 = () => interopDefault(import('../pages/verzekering/doorlopend/thankyou.vue' /* webpackChunkName: "pages/verzekering/doorlopend/thankyou" */))
const _3c495d92 = () => interopDefault(import('../pages/verzekering/doorlopend/calculate.vue' /* webpackChunkName: "pages/verzekering/doorlopend/calculate" */))
const _7425dc88 = () => interopDefault(import('../pages/verzekering/kortlopend/thankyou.vue' /* webpackChunkName: "pages/verzekering/kortlopend/thankyou" */))
const _72a9542f = () => interopDefault(import('../pages/verzekering/kortlopend/calculate.vue' /* webpackChunkName: "pages/verzekering/kortlopend/calculate" */))
const _856924d4 = () => interopDefault(import('../pages/verzekering/skileraar.vue' /* webpackChunkName: "pages/verzekering/skileraar" */))
const _66128b36 = () => interopDefault(import('../pages/verzekering/_slug.vue' /* webpackChunkName: "pages/verzekering/_slug" */))
const _d4fddcf6 = () => interopDefault(import('../pages/videos.vue' /* webpackChunkName: "pages/videos" */))
const _043c5a0c = () => interopDefault(import('../pages/videos/snowshow.vue' /* webpackChunkName: "pages/videos/snowshow" */))
const _1bdfdd96 = () => interopDefault(import('../pages/videos/tipstricks/index.vue' /* webpackChunkName: "pages/videos/tipstricks/index" */))
const _ebccbf70 = () => interopDefault(import('../pages/videos/index.vue' /* webpackChunkName: "pages/videos/index" */))
const _4e43fad1 = () => interopDefault(import('../pages/videos/_country.vue' /* webpackChunkName: "pages/videos/_country" */))
const _1f4d2026 = () => interopDefault(import('../pages/videos/tipstricks/_slug.vue' /* webpackChunkName: "pages/videos/tipstricks/_slug" */))
const _37e9acda = () => interopDefault(import('../pages/weather/index.vue' /* webpackChunkName: "pages/weather/index" */))
const _153ffee0 = () => interopDefault(import('../pages/registerconfirm.vue' /* webpackChunkName: "pages/registerconfirm" */))
const _1f3f301a = () => interopDefault(import('../pages/accommodations/checkout/index.vue' /* webpackChunkName: "pages/accommodations/checkout/index" */))
const _600ba879 = () => interopDefault(import('../pages/accommodations/search.vue' /* webpackChunkName: "pages/accommodations/search" */))
const _19fcda57 = () => interopDefault(import('../pages/accommodations/search/mytrip.vue' /* webpackChunkName: "pages/accommodations/search/mytrip" */))
const _174c303c = () => interopDefault(import('../pages/accommodations/search/index.vue' /* webpackChunkName: "pages/accommodations/search/index" */))
const _3b6111a4 = () => interopDefault(import('../pages/accommodations/search/_id.vue' /* webpackChunkName: "pages/accommodations/search/_id" */))
const _e1598f34 = () => interopDefault(import('../pages/areas/theme/index.vue' /* webpackChunkName: "pages/areas/theme/index" */))
const _43912003 = () => interopDefault(import('../pages/profile/inbox.vue' /* webpackChunkName: "pages/profile/inbox" */))
const _0cb85846 = () => interopDefault(import('../pages/profile/inbox/index.vue' /* webpackChunkName: "pages/profile/inbox/index" */))
const _68ebb42e = () => interopDefault(import('../pages/profile/inbox/_id.vue' /* webpackChunkName: "pages/profile/inbox/_id" */))
const _0253a136 = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _49e02728 = () => interopDefault(import('../pages/profile/membership/index.vue' /* webpackChunkName: "pages/profile/membership/index" */))
const _7a9fb212 = () => interopDefault(import('../pages/profile/membership/benefits.vue' /* webpackChunkName: "pages/profile/membership/benefits" */))
const _00697027 = () => interopDefault(import('../pages/profile/magazine.vue' /* webpackChunkName: "pages/profile/magazine" */))
const _2fef4291 = () => interopDefault(import('../pages/areas/countries/index.vue' /* webpackChunkName: "pages/areas/countries/index" */))
const _4a881aac = () => interopDefault(import('../pages/areas/skipasses.vue' /* webpackChunkName: "pages/areas/skipasses" */))
const _741dc4ec = () => interopDefault(import('../pages/areas/top50.vue' /* webpackChunkName: "pages/areas/top50" */))
const _4dd1e276 = () => interopDefault(import('../pages/areas/similar.vue' /* webpackChunkName: "pages/areas/similar" */))
const _2e0ce2d6 = () => interopDefault(import('../pages/weather/snowheight/index.vue' /* webpackChunkName: "pages/weather/snowheight/index" */))
const _784b0ec8 = () => interopDefault(import('../pages/accommodations/checkout/snowtrex.vue' /* webpackChunkName: "pages/accommodations/checkout/snowtrex" */))
const _2108ca6a = () => interopDefault(import('../pages/accommodations/checkout/success.vue' /* webpackChunkName: "pages/accommodations/checkout/success" */))
const _583b275a = () => interopDefault(import('../pages/gadget/gadget/area.vue' /* webpackChunkName: "pages/gadget/gadget/area" */))
const _23aaa444 = () => interopDefault(import('../pages/gadget/gadget/resort.vue' /* webpackChunkName: "pages/gadget/gadget/resort" */))
const _13d2a2e0 = () => interopDefault(import('../pages/profile/newconversation.vue' /* webpackChunkName: "pages/profile/newconversation" */))
const _01fd8be6 = () => interopDefault(import('../pages/profile/club/event/_event.vue' /* webpackChunkName: "pages/profile/club/event/_event" */))
const _372c59f4 = () => interopDefault(import('../pages/webcams/_webcam.vue' /* webpackChunkName: "pages/webcams/_webcam" */))
const _76b7666f = () => interopDefault(import('../pages/accommodations/_mytrip.vue' /* webpackChunkName: "pages/accommodations/_mytrip" */))
const _10b31db2 = () => interopDefault(import('../pages/accommodations/_mytrip/index.vue' /* webpackChunkName: "pages/accommodations/_mytrip/index" */))
const _6a306b74 = () => interopDefault(import('../pages/accommodations/_mytrip/rental.vue' /* webpackChunkName: "pages/accommodations/_mytrip/rental" */))
const _70bb3eca = () => interopDefault(import('../pages/accommodations/_mytrip/lessons.vue' /* webpackChunkName: "pages/accommodations/_mytrip/lessons" */))
const _050f647e = () => interopDefault(import('../pages/accommodations/_mytrip/skipas.vue' /* webpackChunkName: "pages/accommodations/_mytrip/skipas" */))
const _18bef676 = () => interopDefault(import('../pages/gear/features/_feature.vue' /* webpackChunkName: "pages/gear/features/_feature" */))
const _b3d77030 = () => interopDefault(import('../pages/areas/countries/_country.vue' /* webpackChunkName: "pages/areas/countries/_country" */))
const _651d34dd = () => interopDefault(import('../pages/areas/regions/_region.vue' /* webpackChunkName: "pages/areas/regions/_region" */))
const _4d148034 = () => interopDefault(import('../pages/areas/theme/_theme.vue' /* webpackChunkName: "pages/areas/theme/_theme" */))
const _36f27594 = () => interopDefault(import('../pages/weather/europe/_type.vue' /* webpackChunkName: "pages/weather/europe/_type" */))
const _094f6538 = () => interopDefault(import('../pages/weather/snowheight/_country.vue' /* webpackChunkName: "pages/weather/snowheight/_country" */))
const _a8e99874 = () => interopDefault(import('../pages/resorts/_resort.vue' /* webpackChunkName: "pages/resorts/_resort" */))
const _72f74dc9 = () => interopDefault(import('../pages/resorts/_resort/index.vue' /* webpackChunkName: "pages/resorts/_resort/index" */))
const _2867d439 = () => interopDefault(import('../pages/resorts/_resort/apresski.vue' /* webpackChunkName: "pages/resorts/_resort/apresski" */))
const _bbeaa1a0 = () => interopDefault(import('../pages/resorts/_resort/reachability.vue' /* webpackChunkName: "pages/resorts/_resort/reachability" */))
const _59541e5c = () => interopDefault(import('../pages/resorts/_resort/reviews.vue' /* webpackChunkName: "pages/resorts/_resort/reviews" */))
const _2251b1ca = () => interopDefault(import('../pages/resorts/_resort/forum/index.vue' /* webpackChunkName: "pages/resorts/_resort/forum/index" */))
const _5a73815f = () => interopDefault(import('../pages/resorts/_resort/skimaps.vue' /* webpackChunkName: "pages/resorts/_resort/skimaps" */))
const _7492ee02 = () => interopDefault(import('../pages/resorts/_resort/plan.vue' /* webpackChunkName: "pages/resorts/_resort/plan" */))
const _24ed5c8d = () => interopDefault(import('../pages/resorts/_resort/restaurants.vue' /* webpackChunkName: "pages/resorts/_resort/restaurants" */))
const _50723e3d = () => interopDefault(import('../pages/resorts/_resort/rental.vue' /* webpackChunkName: "pages/resorts/_resort/rental" */))
const _fd8d775e = () => interopDefault(import('../pages/resorts/_resort/lesson.vue' /* webpackChunkName: "pages/resorts/_resort/lesson" */))
const _388bbeec = () => interopDefault(import('../pages/resorts/_resort/skipas.vue' /* webpackChunkName: "pages/resorts/_resort/skipas" */))
const _bcc4cbfa = () => interopDefault(import('../pages/resorts/_resort/snowheight.vue' /* webpackChunkName: "pages/resorts/_resort/snowheight" */))
const _b89bb648 = () => interopDefault(import('../pages/resorts/_resort/snowguarantee.vue' /* webpackChunkName: "pages/resorts/_resort/snowguarantee" */))
const _1ae7861c = () => interopDefault(import('../pages/resorts/_resort/video.vue' /* webpackChunkName: "pages/resorts/_resort/video" */))
const _4fe68182 = () => interopDefault(import('../pages/resorts/_resort/airport.vue' /* webpackChunkName: "pages/resorts/_resort/airport" */))
const _5af75be2 = () => interopDefault(import('../pages/resorts/_resort/webcams.vue' /* webpackChunkName: "pages/resorts/_resort/webcams" */))
const _dc0ba958 = () => interopDefault(import('../pages/resorts/_resort/forecast.vue' /* webpackChunkName: "pages/resorts/_resort/forecast" */))
const _fee64792 = () => interopDefault(import('../pages/resorts/_resort/forum/_topic.vue' /* webpackChunkName: "pages/resorts/_resort/forum/_topic" */))
const _3f2784ea = () => interopDefault(import('../pages/resorts/_resort/_location.vue' /* webpackChunkName: "pages/resorts/_resort/_location" */))
const _6796eb26 = () => interopDefault(import('../pages/resorts/_resort/_accommodationtype.vue' /* webpackChunkName: "pages/resorts/_resort/_accommodationtype" */))
const _c0c9ab2a = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _f0c1f9d4 = () => interopDefault(import('../pages/airports/_airport.vue' /* webpackChunkName: "pages/airports/_airport" */))
const _82ccf8e6 = () => interopDefault(import('../pages/gear/_category/index.vue' /* webpackChunkName: "pages/gear/_category/index" */))
const _cad301f2 = () => interopDefault(import('../pages/profile/_slug.vue' /* webpackChunkName: "pages/profile/_slug" */))
const _a729bb74 = () => interopDefault(import('../pages/areas/_area.vue' /* webpackChunkName: "pages/areas/_area" */))
const _e26b476e = () => interopDefault(import('../pages/areas/_area/index.vue' /* webpackChunkName: "pages/areas/_area/index" */))
const _1e4c35b9 = () => interopDefault(import('../pages/areas/_area/apresski.vue' /* webpackChunkName: "pages/areas/_area/apresski" */))
const _63e250b0 = () => interopDefault(import('../pages/areas/_area/reachability.vue' /* webpackChunkName: "pages/areas/_area/reachability" */))
const _b8a8d00e = () => interopDefault(import('../pages/areas/_area/resorts.vue' /* webpackChunkName: "pages/areas/_area/resorts" */))
const _469f5f52 = () => interopDefault(import('../pages/areas/_area/reviews.vue' /* webpackChunkName: "pages/areas/_area/reviews" */))
const _aa7554ca = () => interopDefault(import('../pages/areas/_area/forum/index.vue' /* webpackChunkName: "pages/areas/_area/forum/index" */))
const _64862042 = () => interopDefault(import('../pages/areas/_area/skimaps.vue' /* webpackChunkName: "pages/areas/_area/skimaps" */))
const _22e40f82 = () => interopDefault(import('../pages/areas/_area/plan.vue' /* webpackChunkName: "pages/areas/_area/plan" */))
const _3e48e9e6 = () => interopDefault(import('../pages/areas/_area/restaurants.vue' /* webpackChunkName: "pages/areas/_area/restaurants" */))
const _a1fe0086 = () => interopDefault(import('../pages/areas/_area/rental.vue' /* webpackChunkName: "pages/areas/_area/rental" */))
const _406ff45e = () => interopDefault(import('../pages/areas/_area/lesson.vue' /* webpackChunkName: "pages/areas/_area/lesson" */))
const _4248e20a = () => interopDefault(import('../pages/areas/_area/skipas.vue' /* webpackChunkName: "pages/areas/_area/skipas" */))
const _a020c8fa = () => interopDefault(import('../pages/areas/_area/snowheight.vue' /* webpackChunkName: "pages/areas/_area/snowheight" */))
const _1cceb35c = () => interopDefault(import('../pages/areas/_area/snowguarantee.vue' /* webpackChunkName: "pages/areas/_area/snowguarantee" */))
const _7a164dbc = () => interopDefault(import('../pages/areas/_area/similar.vue' /* webpackChunkName: "pages/areas/_area/similar" */))
const _0e5f4b72 = () => interopDefault(import('../pages/areas/_area/video.vue' /* webpackChunkName: "pages/areas/_area/video" */))
const _79a01ffc = () => interopDefault(import('../pages/areas/_area/airport.vue' /* webpackChunkName: "pages/areas/_area/airport" */))
const _45cdc08f = () => interopDefault(import('../pages/areas/_area/webcams.vue' /* webpackChunkName: "pages/areas/_area/webcams" */))
const _f042e658 = () => interopDefault(import('../pages/areas/_area/forecast.vue' /* webpackChunkName: "pages/areas/_area/forecast" */))
const _42647db7 = () => interopDefault(import('../pages/areas/_area/forum/_topic.vue' /* webpackChunkName: "pages/areas/_area/forum/_topic" */))
const _6a8f6ca6 = () => interopDefault(import('../pages/areas/_area/_accommodationtype.vue' /* webpackChunkName: "pages/areas/_area/_accommodationtype" */))
const _66e7ffb4 = () => interopDefault(import('../pages/clusters/_cluster.vue' /* webpackChunkName: "pages/clusters/_cluster" */))
const _4520d0a1 = () => interopDefault(import('../pages/weather/_type.vue' /* webpackChunkName: "pages/weather/_type" */))
const _c616eb36 = () => interopDefault(import('../pages/gear/_category/product/_product.vue' /* webpackChunkName: "pages/gear/_category/product/_product" */))
const _5ea70460 = () => interopDefault(import('../pages/gear/_category/_cluster.vue' /* webpackChunkName: "pages/gear/_category/_cluster" */))
const _5bd50c35 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _17367a94 = () => interopDefault(import('../pages/_contentpage.vue' /* webpackChunkName: "pages/_contentpage" */))
const _41c5e8b9 = () => interopDefault(import('../pages/_contentpage/index.vue' /* webpackChunkName: "pages/_contentpage/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/aanbiedingen",
    component: _00dadf78,
    pathToRegexpOptions: {"strict":true},
    name: "offers___nl-NL"
  }, {
    path: "/aanbiedingen",
    component: _00dadf78,
    pathToRegexpOptions: {"strict":true},
    name: "offers___nl-BE"
  }, {
    path: "/aanmelden",
    component: _27049610,
    pathToRegexpOptions: {"strict":true},
    name: "register___nl-NL"
  }, {
    path: "/aanmelden",
    component: _27049610,
    pathToRegexpOptions: {"strict":true},
    name: "register___nl-BE"
  }, {
    path: "/academy",
    component: _2387c2e2,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/academy",
      component: _26d5845c,
      pathToRegexpOptions: {"strict":true},
      name: "academy___nl-NL"
    }, {
      path: "/academy/:level",
      component: _da3ffa10,
      pathToRegexpOptions: {"strict":true},
      name: "academy-level___nl-NL"
    }, {
      path: "/academy/:level/:lesson",
      component: _49d94e7d,
      pathToRegexpOptions: {"strict":true},
      name: "academy-level-lesson___nl-NL"
    }]
  }, {
    path: "/academy",
    component: _2387c2e2,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _26d5845c,
      pathToRegexpOptions: {"strict":true},
      name: "academy___de-DE"
    }, {
      path: ":level",
      component: _da3ffa10,
      pathToRegexpOptions: {"strict":true},
      name: "academy-level___de-DE"
    }, {
      path: ":level/:lesson",
      component: _49d94e7d,
      pathToRegexpOptions: {"strict":true},
      name: "academy-level-lesson___de-DE"
    }]
  }, {
    path: "/academy",
    component: _2387c2e2,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/academy",
      component: _26d5845c,
      pathToRegexpOptions: {"strict":true},
      name: "academy___nl-BE"
    }, {
      path: "/academy/:level",
      component: _da3ffa10,
      pathToRegexpOptions: {"strict":true},
      name: "academy-level___nl-BE"
    }, {
      path: "/academy/:level/:lesson",
      component: _49d94e7d,
      pathToRegexpOptions: {"strict":true},
      name: "academy-level-lesson___nl-BE"
    }]
  }, {
    path: "/accommodatie",
    component: _58659311,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations___nl-NL"
  }, {
    path: "/accommodatie",
    component: _58659311,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations___nl-BE"
  }, {
    path: "/accommodations",
    component: _58659311,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations___de-DE"
  }, {
    path: "/angebote-skiurlaub",
    component: _00dadf78,
    pathToRegexpOptions: {"strict":true},
    name: "offers___de-DE"
  }, {
    path: "/anmelden",
    component: _27049610,
    pathToRegexpOptions: {"strict":true},
    name: "register___de-DE"
  }, {
    path: "/benchmark",
    component: _236ef960,
    pathToRegexpOptions: {"strict":true},
    name: "benchmark___nl-NL"
  }, {
    path: "/benchmark",
    component: _236ef960,
    pathToRegexpOptions: {"strict":true},
    name: "benchmark___de-DE"
  }, {
    path: "/benchmark",
    component: _236ef960,
    pathToRegexpOptions: {"strict":true},
    name: "benchmark___nl-BE"
  }, {
    path: "/blog",
    component: _4ace1cc6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/blog",
      component: _6941e340,
      pathToRegexpOptions: {"strict":true},
      name: "weblog___de-DE"
    }, {
      path: "/blog/*",
      component: _6caf25d0,
      pathToRegexpOptions: {"strict":true},
      name: "weblog-slug___de-DE"
    }]
  }, {
    path: "/club",
    component: _36163d23,
    pathToRegexpOptions: {"strict":true},
    name: "club___nl-NL"
  }, {
    path: "/club",
    component: _36163d23,
    pathToRegexpOptions: {"strict":true},
    name: "club___de-DE"
  }, {
    path: "/club",
    component: _36163d23,
    pathToRegexpOptions: {"strict":true},
    name: "club___nl-BE"
  }, {
    path: "/createprofile",
    component: _448e5810,
    pathToRegexpOptions: {"strict":true},
    name: "createprofile___nl-NL"
  }, {
    path: "/createprofile",
    component: _448e5810,
    pathToRegexpOptions: {"strict":true},
    name: "createprofile___de-DE"
  }, {
    path: "/createprofile",
    component: _448e5810,
    pathToRegexpOptions: {"strict":true},
    name: "createprofile___nl-BE"
  }, {
    path: "/flughaefen",
    component: _1bf864b8,
    pathToRegexpOptions: {"strict":true},
    name: "airports___de-DE"
  }, {
    path: "/forum",
    component: _0632b104,
    pathToRegexpOptions: {"strict":true},
    name: "forum___nl-NL",
    children: [{
      path: "/forum/regels",
      component: _38ea2ba8,
      pathToRegexpOptions: {"strict":true},
      name: "forum-rules___nl-NL"
    }, {
      path: "/forum/topic/:slug",
      component: _475c4c4b,
      pathToRegexpOptions: {"strict":true},
      name: "forum-topic___nl-NL"
    }, {
      path: "/forum/:category?",
      component: _2de01612,
      pathToRegexpOptions: {"strict":true},
      name: "forum-category___nl-NL"
    }]
  }, {
    path: "/forum",
    component: _0632b104,
    pathToRegexpOptions: {"strict":true},
    name: "forum___de-DE",
    children: [{
      path: "rules",
      component: _38ea2ba8,
      pathToRegexpOptions: {"strict":true},
      name: "forum-rules___de-DE"
    }, {
      path: ":category?",
      component: _2de01612,
      pathToRegexpOptions: {"strict":true},
      name: "forum-category___de-DE"
    }, {
      path: ":topic?",
      component: _475c4c4b,
      pathToRegexpOptions: {"strict":true},
      name: "forum-topic___de-DE"
    }]
  }, {
    path: "/forum",
    component: _0632b104,
    pathToRegexpOptions: {"strict":true},
    name: "forum___nl-BE",
    children: [{
      path: "/forum/regels",
      component: _38ea2ba8,
      pathToRegexpOptions: {"strict":true},
      name: "forum-rules___nl-BE"
    }, {
      path: "/forum/topic/:slug",
      component: _475c4c4b,
      pathToRegexpOptions: {"strict":true},
      name: "forum-topic___nl-BE"
    }, {
      path: "/forum/:category?",
      component: _2de01612,
      pathToRegexpOptions: {"strict":true},
      name: "forum-category___nl-BE"
    }]
  }, {
    path: "/gear",
    component: _a1715202,
    pathToRegexpOptions: {"strict":true},
    name: "gear___de-DE"
  }, {
    path: "/jumbo",
    component: _c487259c,
    pathToRegexpOptions: {"strict":true},
    name: "jumbo___nl-NL"
  }, {
    path: "/jumbo",
    component: _c487259c,
    pathToRegexpOptions: {"strict":true},
    name: "jumbo___de-DE"
  }, {
    path: "/jumbo",
    component: _c487259c,
    pathToRegexpOptions: {"strict":true},
    name: "jumbo___nl-BE"
  }, {
    path: "/localdestinations",
    component: _30b390f3,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/outdoorbanen",
      component: _14b828ba,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-dryslopes___nl-NL"
    }, {
      path: "/rolbanen",
      component: _d887050a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-revolvingslopes___nl-NL"
    }, {
      path: "/skihallen",
      component: _7d33d778,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls___nl-NL"
    }, {
      path: "/wintersport-in-de-buurt",
      component: _6a1e5536,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations___nl-NL"
    }, {
      path: "/outdoorbanen/:slug",
      component: _13018772,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-dryslopes-slug___nl-NL"
    }, {
      path: "/rolbanen/:slug",
      component: _dbf4479a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-revolvingslopes-slug___nl-NL"
    }, {
      path: "/skihallen/:country(belgie|duitsland|nederland)",
      component: _660035a1,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls-country___nl-NL"
    }, {
      path: "/skihallen/:slug",
      component: _7b7d3630,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls-slug___nl-NL"
    }, {
      path: "/wintersport-in-de-buurt/:discipline(langlauf|sneeuwfit|para-wintersport|wedstrijdteams|clubs|skireizen)",
      component: _8103241a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-discipline___nl-NL"
    }, {
      path: "/wintersport-in-de-buurt/:organisation",
      component: _117abf9d,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-organisation___nl-NL"
    }]
  }, {
    path: "/localdestinations",
    component: _30b390f3,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6a1e5536,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations___de-DE"
    }, {
      path: "dryslopes",
      component: _14b828ba,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-dryslopes___de-DE"
    }, {
      path: "revolvingslopes",
      component: _d887050a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-revolvingslopes___de-DE"
    }, {
      path: "skihalls",
      component: _7d33d778,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls___de-DE"
    }, {
      path: "dryslopes/:slug",
      component: _13018772,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-dryslopes-slug___de-DE"
    }, {
      path: "revolvingslopes/:slug",
      component: _dbf4479a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-revolvingslopes-slug___de-DE"
    }, {
      path: "skihalls/:country",
      component: _660035a1,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls-country___de-DE"
    }, {
      path: "skihalls/:slug",
      component: _7b7d3630,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls-slug___de-DE"
    }, {
      path: ":discipline",
      component: _8103241a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-discipline___de-DE"
    }, {
      path: ":organisation",
      component: _117abf9d,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-organisation___de-DE"
    }]
  }, {
    path: "/localdestinations",
    component: _30b390f3,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/outdoorbanen",
      component: _14b828ba,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-dryslopes___nl-BE"
    }, {
      path: "/rolbanen",
      component: _d887050a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-revolvingslopes___nl-BE"
    }, {
      path: "/skihallen",
      component: _7d33d778,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls___nl-BE"
    }, {
      path: "/wintersport-in-de-buurt",
      component: _6a1e5536,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations___nl-BE"
    }, {
      path: "/outdoorbanen/:slug",
      component: _13018772,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-dryslopes-slug___nl-BE"
    }, {
      path: "/rolbanen/:slug",
      component: _dbf4479a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-revolvingslopes-slug___nl-BE"
    }, {
      path: "/skihallen/:country(belgie|duitsland|nederland)",
      component: _660035a1,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls-country___nl-BE"
    }, {
      path: "/skihallen/:slug",
      component: _7b7d3630,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-skihalls-slug___nl-BE"
    }, {
      path: "/wintersport-in-de-buurt/:discipline(langlauf|sneeuwfit|para-wintersport|wedstrijdteams|clubs|skireizen)",
      component: _8103241a,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-discipline___nl-BE"
    }, {
      path: "/wintersport-in-de-buurt/:organisation",
      component: _117abf9d,
      pathToRegexpOptions: {"strict":true},
      name: "localdestinations-organisation___nl-BE"
    }]
  }, {
    path: "/login",
    component: _257caf4c,
    pathToRegexpOptions: {"strict":true},
    name: "login___nl-NL"
  }, {
    path: "/login",
    component: _257caf4c,
    pathToRegexpOptions: {"strict":true},
    name: "login___de-DE"
  }, {
    path: "/login",
    component: _257caf4c,
    pathToRegexpOptions: {"strict":true},
    name: "login___nl-BE"
  }, {
    path: "/materiaal",
    component: _a1715202,
    pathToRegexpOptions: {"strict":true},
    name: "gear___nl-NL"
  }, {
    path: "/materiaal",
    component: _a1715202,
    pathToRegexpOptions: {"strict":true},
    name: "gear___nl-BE"
  }, {
    path: "/memberships",
    component: _551d5500,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/lidmaatschappen",
      component: _5e53c8fa,
      pathToRegexpOptions: {"strict":true},
      name: "memberships___nl-NL"
    }, {
      path: "/lidmaatschappen/lid-worden-:type",
      component: _43e5816c,
      pathToRegexpOptions: {"strict":true},
      name: "memberships-type___nl-NL"
    }]
  }, {
    path: "/memberships",
    component: _551d5500,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _5e53c8fa,
      pathToRegexpOptions: {"strict":true},
      name: "memberships___de-DE"
    }, {
      path: ":type",
      component: _43e5816c,
      pathToRegexpOptions: {"strict":true},
      name: "memberships-type___de-DE"
    }]
  }, {
    path: "/memberships",
    component: _551d5500,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/lidmaatschappen",
      component: _5e53c8fa,
      pathToRegexpOptions: {"strict":true},
      name: "memberships___nl-BE"
    }, {
      path: "/lidmaatschappen/lid-worden-:type",
      component: _43e5816c,
      pathToRegexpOptions: {"strict":true},
      name: "memberships-type___nl-BE"
    }]
  }, {
    path: "/nachrichten",
    component: _3ed0ccc0,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/nachrichten",
      component: _1a80c343,
      pathToRegexpOptions: {"strict":true},
      name: "news___de-DE"
    }, {
      path: "/nachrichten/*",
      component: _18ca21fb,
      pathToRegexpOptions: {"strict":true},
      name: "news-slug___de-DE"
    }]
  }, {
    path: "/newsletter",
    component: _8775a034,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___de-DE"
  }, {
    path: "/nieuws",
    component: _3ed0ccc0,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/nieuws",
      component: _1a80c343,
      pathToRegexpOptions: {"strict":true},
      name: "news___nl-NL"
    }, {
      path: "/nieuws/*",
      component: _18ca21fb,
      pathToRegexpOptions: {"strict":true},
      name: "news-slug___nl-NL"
    }]
  }, {
    path: "/nieuws",
    component: _3ed0ccc0,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/nieuws",
      component: _1a80c343,
      pathToRegexpOptions: {"strict":true},
      name: "news___nl-BE"
    }, {
      path: "/nieuws/*",
      component: _18ca21fb,
      pathToRegexpOptions: {"strict":true},
      name: "news-slug___nl-BE"
    }]
  }, {
    path: "/nieuwsbrief",
    component: _8775a034,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___nl-NL"
  }, {
    path: "/nieuwsbrief",
    component: _8775a034,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter___nl-BE"
  }, {
    path: "/over-ons",
    component: _3f665d30,
    pathToRegexpOptions: {"strict":true},
    name: "over-ons___nl-NL"
  }, {
    path: "/over-ons",
    component: _3f665d30,
    pathToRegexpOptions: {"strict":true},
    name: "over-ons___nl-BE"
  }, {
    path: "/panel",
    component: _339a0467,
    pathToRegexpOptions: {"strict":true},
    name: "panel___nl-NL"
  }, {
    path: "/panel",
    component: _339a0467,
    pathToRegexpOptions: {"strict":true},
    name: "panel___de-DE"
  }, {
    path: "/panel",
    component: _339a0467,
    pathToRegexpOptions: {"strict":true},
    name: "panel___nl-BE"
  }, {
    path: "/podcast",
    component: _b34492b2,
    pathToRegexpOptions: {"strict":true},
    name: "podcast___nl-NL"
  }, {
    path: "/podcast",
    component: _b34492b2,
    pathToRegexpOptions: {"strict":true},
    name: "podcast___de-DE"
  }, {
    path: "/podcast",
    component: _b34492b2,
    pathToRegexpOptions: {"strict":true},
    name: "podcast___nl-BE"
  }, {
    path: "/reset-password",
    component: _2560f206,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password___nl-NL"
  }, {
    path: "/reset-password",
    component: _2560f206,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password___de-DE"
  }, {
    path: "/reset-password",
    component: _2560f206,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password___nl-BE"
  }, {
    path: "/schneealarm",
    component: _745eba91,
    pathToRegexpOptions: {"strict":true},
    name: "snowalarm___de-DE"
  }, {
    path: "/skigebieden",
    component: _4b98c2a8,
    pathToRegexpOptions: {"strict":true},
    name: "areas___nl-NL"
  }, {
    path: "/skigebieden",
    component: _4b98c2a8,
    pathToRegexpOptions: {"strict":true},
    name: "areas___nl-BE"
  }, {
    path: "/skigebiete",
    component: _4b98c2a8,
    pathToRegexpOptions: {"strict":true},
    name: "areas___de-DE"
  }, {
    path: "/skiles",
    component: _5bfa8b44,
    pathToRegexpOptions: {"strict":true},
    name: "lessons___nl-NL"
  }, {
    path: "/skiles",
    component: _5bfa8b44,
    pathToRegexpOptions: {"strict":true},
    name: "lessons___de-DE"
  }, {
    path: "/skiles",
    component: _5bfa8b44,
    pathToRegexpOptions: {"strict":true},
    name: "lessons___nl-BE"
  }, {
    path: "/skis-huren",
    component: _2c2e041c,
    pathToRegexpOptions: {"strict":true},
    name: "rentals___nl-NL"
  }, {
    path: "/skis-huren",
    component: _2c2e041c,
    pathToRegexpOptions: {"strict":true},
    name: "rentals___nl-BE"
  }, {
    path: "/skiverleih",
    component: _2c2e041c,
    pathToRegexpOptions: {"strict":true},
    name: "rentals___de-DE"
  }, {
    path: "/sneeuwalarm",
    component: _745eba91,
    pathToRegexpOptions: {"strict":true},
    name: "snowalarm___nl-NL"
  }, {
    path: "/sneeuwalarm",
    component: _745eba91,
    pathToRegexpOptions: {"strict":true},
    name: "snowalarm___nl-BE"
  }, {
    path: "/suchen",
    component: _6ae95856,
    pathToRegexpOptions: {"strict":true},
    name: "search___de-DE"
  }, {
    path: "/team",
    component: _3f665d30,
    pathToRegexpOptions: {"strict":true},
    name: "over-ons___de-DE"
  }, {
    path: "/trips",
    component: _75488651,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _9a1d67d8,
      pathToRegexpOptions: {"strict":true},
      name: "trips___de-DE"
    }, {
      path: ":slug",
      component: _9d8aaa68,
      pathToRegexpOptions: {"strict":true},
      name: "trips-slug___de-DE"
    }]
  }, {
    path: "/vergessen",
    component: _3aa0c860,
    pathToRegexpOptions: {"strict":true},
    name: "forgot___de-DE"
  }, {
    path: "/vergeten",
    component: _3aa0c860,
    pathToRegexpOptions: {"strict":true},
    name: "forgot___nl-NL"
  }, {
    path: "/vergeten",
    component: _3aa0c860,
    pathToRegexpOptions: {"strict":true},
    name: "forgot___nl-BE"
  }, {
    path: "/verzekering",
    component: _20037e3b,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/verzekering",
      component: _67c92c7e,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering___nl-NL"
    }, {
      path: "/verzekering/basis-reisverzekering",
      component: _c1e19e26,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis___nl-NL"
    }, {
      path: "/verzekering/doorlopende-reisverzekering",
      component: _5732e523,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend___nl-NL"
    }, {
      path: "/verzekering/kortlopende-reisverzekering",
      component: _c8adb7ca,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend___nl-NL"
    }, {
      path: "/verzekering/login",
      component: _3170cf95,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-login___nl-NL"
    }, {
      path: "/verzekering/profiel",
      component: _efce65fa,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-profiel___nl-NL"
    }, {
      path: "/verzekering/reisverzekering",
      component: _62335187,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-reisverzekering___nl-NL"
    }, {
      path: "/verzekering/zorgverzekering",
      component: _7f48c74c,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-zorgverzekering___nl-NL"
    }, {
      path: "/verzekering/basis-reisverzekering/afgesloten",
      component: _469afbaa,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis-thankyou___nl-NL"
    }, {
      path: "/verzekering/basis-reisverzekering/afsluiten",
      component: _07b8a801,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis-calculate___nl-NL"
    }, {
      path: "/verzekering/doorlopende-reisverzekering/afgesloten",
      component: _6cf95898,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend-thankyou___nl-NL"
    }, {
      path: "/verzekering/doorlopende-reisverzekering/afsluiten",
      component: _3c495d92,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend-calculate___nl-NL"
    }, {
      path: "/verzekering/kortlopende-reisverzekering/afgesloten",
      component: _7425dc88,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend-thankyou___nl-NL"
    }, {
      path: "/verzekering/kortlopende-reisverzekering/afsluiten",
      component: _72a9542f,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend-calculate___nl-NL"
    }, {
      path: "/verzekering/skileraar/:code",
      component: _856924d4,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-skileraar___nl-NL"
    }, {
      path: "/verzekering/:slug",
      component: _66128b36,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-slug___nl-NL"
    }]
  }, {
    path: "/verzekering",
    component: _20037e3b,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _67c92c7e,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering___de-DE"
    }, {
      path: "basis",
      component: _c1e19e26,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis___de-DE"
    }, {
      path: "doorlopend",
      component: _5732e523,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend___de-DE"
    }, {
      path: "kortlopend",
      component: _c8adb7ca,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend___de-DE"
    }, {
      path: "login",
      component: _3170cf95,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-login___de-DE"
    }, {
      path: "profiel",
      component: _efce65fa,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-profiel___de-DE"
    }, {
      path: "reisverzekering",
      component: _62335187,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-reisverzekering___de-DE"
    }, {
      path: "skileraar",
      component: _856924d4,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-skileraar___de-DE"
    }, {
      path: "zorgverzekering",
      component: _7f48c74c,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-zorgverzekering___de-DE"
    }, {
      path: "basis/calculate",
      component: _07b8a801,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis-calculate___de-DE"
    }, {
      path: "basis/thankyou",
      component: _469afbaa,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis-thankyou___de-DE"
    }, {
      path: "doorlopend/calculate",
      component: _3c495d92,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend-calculate___de-DE"
    }, {
      path: "doorlopend/thankyou",
      component: _6cf95898,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend-thankyou___de-DE"
    }, {
      path: "kortlopend/calculate",
      component: _72a9542f,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend-calculate___de-DE"
    }, {
      path: "kortlopend/thankyou",
      component: _7425dc88,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend-thankyou___de-DE"
    }, {
      path: ":slug",
      component: _66128b36,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-slug___de-DE"
    }]
  }, {
    path: "/verzekering",
    component: _20037e3b,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _67c92c7e,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering___nl-BE"
    }, {
      path: "basis",
      component: _c1e19e26,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis___nl-BE"
    }, {
      path: "doorlopend",
      component: _5732e523,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend___nl-BE"
    }, {
      path: "kortlopend",
      component: _c8adb7ca,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend___nl-BE"
    }, {
      path: "login",
      component: _3170cf95,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-login___nl-BE"
    }, {
      path: "profiel",
      component: _efce65fa,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-profiel___nl-BE"
    }, {
      path: "reisverzekering",
      component: _62335187,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-reisverzekering___nl-BE"
    }, {
      path: "skileraar",
      component: _856924d4,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-skileraar___nl-BE"
    }, {
      path: "zorgverzekering",
      component: _7f48c74c,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-zorgverzekering___nl-BE"
    }, {
      path: "basis/calculate",
      component: _07b8a801,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis-calculate___nl-BE"
    }, {
      path: "basis/thankyou",
      component: _469afbaa,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-basis-thankyou___nl-BE"
    }, {
      path: "doorlopend/calculate",
      component: _3c495d92,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend-calculate___nl-BE"
    }, {
      path: "doorlopend/thankyou",
      component: _6cf95898,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-doorlopend-thankyou___nl-BE"
    }, {
      path: "kortlopend/calculate",
      component: _72a9542f,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend-calculate___nl-BE"
    }, {
      path: "kortlopend/thankyou",
      component: _7425dc88,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-kortlopend-thankyou___nl-BE"
    }, {
      path: "/verzekering/:slug",
      component: _66128b36,
      pathToRegexpOptions: {"strict":true},
      name: "verzekering-slug___nl-BE"
    }]
  }, {
    path: "/videos",
    component: _d4fddcf6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/snowshow",
      component: _043c5a0c,
      pathToRegexpOptions: {"strict":true},
      name: "videos-snowshow___nl-NL"
    }, {
      path: "/tips-en-tricks",
      component: _1bdfdd96,
      pathToRegexpOptions: {"strict":true},
      name: "videos-tipstricks___nl-NL"
    }, {
      path: "/videos",
      component: _ebccbf70,
      pathToRegexpOptions: {"strict":true},
      name: "videos___nl-NL"
    }, {
      path: "/videos/land/:country",
      component: _4e43fad1,
      pathToRegexpOptions: {"strict":true},
      name: "videos-country___nl-NL"
    }, {
      path: "/tips-en-tricks/:slug",
      component: _1f4d2026,
      pathToRegexpOptions: {"strict":true},
      name: "videos-tipstricks-slug___nl-NL"
    }]
  }, {
    path: "/videos",
    component: _d4fddcf6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _ebccbf70,
      pathToRegexpOptions: {"strict":true},
      name: "videos___de-DE"
    }, {
      path: "snowshow",
      component: _043c5a0c,
      pathToRegexpOptions: {"strict":true},
      name: "videos-snowshow___de-DE"
    }, {
      path: "tipstricks",
      component: _1bdfdd96,
      pathToRegexpOptions: {"strict":true},
      name: "videos-tipstricks___de-DE"
    }, {
      path: "tipstricks/:slug",
      component: _1f4d2026,
      pathToRegexpOptions: {"strict":true},
      name: "videos-tipstricks-slug___de-DE"
    }, {
      path: ":country",
      component: _4e43fad1,
      pathToRegexpOptions: {"strict":true},
      name: "videos-country___de-DE"
    }]
  }, {
    path: "/videos",
    component: _d4fddcf6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/snowshow",
      component: _043c5a0c,
      pathToRegexpOptions: {"strict":true},
      name: "videos-snowshow___nl-BE"
    }, {
      path: "/tips-en-tricks",
      component: _1bdfdd96,
      pathToRegexpOptions: {"strict":true},
      name: "videos-tipstricks___nl-BE"
    }, {
      path: "/videos",
      component: _ebccbf70,
      pathToRegexpOptions: {"strict":true},
      name: "videos___nl-BE"
    }, {
      path: "/videos/land/:country",
      component: _4e43fad1,
      pathToRegexpOptions: {"strict":true},
      name: "videos-country___nl-BE"
    }, {
      path: "/tips-en-tricks/:slug",
      component: _1f4d2026,
      pathToRegexpOptions: {"strict":true},
      name: "videos-tipstricks-slug___nl-BE"
    }]
  }, {
    path: "/vliegvelden",
    component: _1bf864b8,
    pathToRegexpOptions: {"strict":true},
    name: "airports___nl-NL"
  }, {
    path: "/vliegvelden",
    component: _1bf864b8,
    pathToRegexpOptions: {"strict":true},
    name: "airports___nl-BE"
  }, {
    path: "/weblog",
    component: _4ace1cc6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/weblog",
      component: _6941e340,
      pathToRegexpOptions: {"strict":true},
      name: "weblog___nl-NL"
    }, {
      path: "/weblog/*",
      component: _6caf25d0,
      pathToRegexpOptions: {"strict":true},
      name: "weblog-slug___nl-NL"
    }]
  }, {
    path: "/weblog",
    component: _4ace1cc6,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/weblog",
      component: _6941e340,
      pathToRegexpOptions: {"strict":true},
      name: "weblog___nl-BE"
    }, {
      path: "/weblog/*",
      component: _6caf25d0,
      pathToRegexpOptions: {"strict":true},
      name: "weblog-slug___nl-BE"
    }]
  }, {
    path: "/weersneeuw",
    component: _37e9acda,
    pathToRegexpOptions: {"strict":true},
    name: "weather___nl-NL"
  }, {
    path: "/weersneeuw",
    component: _37e9acda,
    pathToRegexpOptions: {"strict":true},
    name: "weather___nl-BE"
  }, {
    path: "/wetter-schnee",
    component: _37e9acda,
    pathToRegexpOptions: {"strict":true},
    name: "weather___de-DE"
  }, {
    path: "/wintersport-in-nederland",
    component: _75488651,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/wintersport-in-nederland",
      component: _9a1d67d8,
      pathToRegexpOptions: {"strict":true},
      name: "trips___nl-NL"
    }, {
      path: "/wintersport-in-nederland/:slug",
      component: _9d8aaa68,
      pathToRegexpOptions: {"strict":true},
      name: "trips-slug___nl-NL"
    }]
  }, {
    path: "/wintersport-in-nederland",
    component: _75488651,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/wintersport-in-nederland",
      component: _9a1d67d8,
      pathToRegexpOptions: {"strict":true},
      name: "trips___nl-BE"
    }, {
      path: "/wintersport-in-nederland/:slug",
      component: _9d8aaa68,
      pathToRegexpOptions: {"strict":true},
      name: "trips-slug___nl-BE"
    }]
  }, {
    path: "/zoeken",
    component: _6ae95856,
    pathToRegexpOptions: {"strict":true},
    name: "search___nl-NL"
  }, {
    path: "/zoeken",
    component: _6ae95856,
    pathToRegexpOptions: {"strict":true},
    name: "search___nl-BE"
  }, {
    path: "/aanmelden/bedankt",
    component: _153ffee0,
    pathToRegexpOptions: {"strict":true},
    name: "registerconfirm___nl-NL"
  }, {
    path: "/aanmelden/bedankt",
    component: _153ffee0,
    pathToRegexpOptions: {"strict":true},
    name: "registerconfirm___nl-BE"
  }, {
    path: "/accommodations/checkout",
    component: _1f3f301a,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout___de-DE"
  }, {
    path: "/accommodations/search",
    component: _600ba879,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/accommodatie/mijnreis",
      component: _19fcda57,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search-mytrip___nl-NL"
    }, {
      path: "/accommodatie/resultaten",
      component: _174c303c,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search___nl-NL"
    }, {
      path: "/accommodatie/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
      component: _3b6111a4,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search-id___nl-NL"
    }]
  }, {
    path: "/accommodations/search",
    component: _600ba879,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _174c303c,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search___de-DE"
    }, {
      path: "mytrip",
      component: _19fcda57,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search-mytrip___de-DE"
    }, {
      path: ":id",
      component: _3b6111a4,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search-id___de-DE"
    }]
  }, {
    path: "/accommodations/search",
    component: _600ba879,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/accommodatie/mijnreis",
      component: _19fcda57,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search-mytrip___nl-BE"
    }, {
      path: "/accommodatie/resultaten",
      component: _174c303c,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search___nl-BE"
    }, {
      path: "/accommodatie/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
      component: _3b6111a4,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-search-id___nl-BE"
    }]
  }, {
    path: "/anmelden/danke",
    component: _153ffee0,
    pathToRegexpOptions: {"strict":true},
    name: "registerconfirm___de-DE"
  }, {
    path: "/areas/theme",
    component: _e1598f34,
    pathToRegexpOptions: {"strict":true},
    name: "areas-theme___de-DE"
  }, {
    path: "/profiel/inbox",
    component: _43912003,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/profiel/inbox",
      component: _0cb85846,
      pathToRegexpOptions: {"strict":true},
      name: "profile-inbox___nl-NL"
    }, {
      path: "/profiel/inbox/:id",
      component: _68ebb42e,
      pathToRegexpOptions: {"strict":true},
      name: "profile-inbox-id___nl-NL"
    }]
  }, {
    path: "/profiel/inbox",
    component: _43912003,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/profiel/inbox",
      component: _0cb85846,
      pathToRegexpOptions: {"strict":true},
      name: "profile-inbox___nl-BE"
    }, {
      path: ":id",
      component: _68ebb42e,
      pathToRegexpOptions: {"strict":true},
      name: "profile-inbox-id___nl-BE"
    }]
  }, {
    path: "/profiel/instellingen",
    component: _0253a136,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___nl-NL"
  }, {
    path: "/profiel/instellingen",
    component: _0253a136,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___nl-BE"
  }, {
    path: "/profiel/lidmaatschap",
    component: _49e02728,
    pathToRegexpOptions: {"strict":true},
    name: "profile-membership___nl-NL"
  }, {
    path: "/profiel/lidmaatschap",
    component: _49e02728,
    pathToRegexpOptions: {"strict":true},
    name: "profile-membership___nl-BE"
  }, {
    path: "/profiel/voordelen",
    component: _7a9fb212,
    pathToRegexpOptions: {"strict":true},
    name: "profile-membership-benefits___nl-NL"
  }, {
    path: "/profiel/voordelen",
    component: _7a9fb212,
    pathToRegexpOptions: {"strict":true},
    name: "profile-membership-benefits___nl-BE"
  }, {
    path: "/profil/einstellungen",
    component: _0253a136,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings___de-DE"
  }, {
    path: "/profile/inbox",
    component: _43912003,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/profil/posteingang",
      component: _0cb85846,
      pathToRegexpOptions: {"strict":true},
      name: "profile-inbox___de-DE"
    }, {
      path: ":id",
      component: _68ebb42e,
      pathToRegexpOptions: {"strict":true},
      name: "profile-inbox-id___de-DE"
    }]
  }, {
    path: "/profile/magazine",
    component: _00697027,
    pathToRegexpOptions: {"strict":true},
    name: "profile-magazine___de-DE"
  }, {
    path: "/profile/membership",
    component: _49e02728,
    pathToRegexpOptions: {"strict":true},
    name: "profile-membership___de-DE"
  }, {
    path: "/skigebieden/overzicht",
    component: _2fef4291,
    pathToRegexpOptions: {"strict":true},
    name: "areas-countries___nl-NL"
  }, {
    path: "/skigebieden/overzicht",
    component: _2fef4291,
    pathToRegexpOptions: {"strict":true},
    name: "areas-countries___nl-BE"
  }, {
    path: "/skigebieden/skipasprijzen",
    component: _4a881aac,
    pathToRegexpOptions: {"strict":true},
    name: "areas-skipasses___nl-NL"
  }, {
    path: "/skigebieden/skipasprijzen",
    component: _4a881aac,
    pathToRegexpOptions: {"strict":true},
    name: "areas-skipasses___nl-BE"
  }, {
    path: "/skigebieden/thema",
    component: _e1598f34,
    pathToRegexpOptions: {"strict":true},
    name: "areas-theme___nl-NL"
  }, {
    path: "/skigebieden/thema",
    component: _e1598f34,
    pathToRegexpOptions: {"strict":true},
    name: "areas-theme___nl-BE"
  }, {
    path: "/skigebieden/top50",
    component: _741dc4ec,
    pathToRegexpOptions: {"strict":true},
    name: "areas-top50___nl-NL"
  }, {
    path: "/skigebieden/top50",
    component: _741dc4ec,
    pathToRegexpOptions: {"strict":true},
    name: "areas-top50___nl-BE"
  }, {
    path: "/skigebieden/vergelijken",
    component: _4dd1e276,
    pathToRegexpOptions: {"strict":true},
    name: "areas-similar___nl-NL"
  }, {
    path: "/skigebieden/vergelijken",
    component: _4dd1e276,
    pathToRegexpOptions: {"strict":true},
    name: "areas-similar___nl-BE"
  }, {
    path: "/skigebiete/skipasspreise",
    component: _4a881aac,
    pathToRegexpOptions: {"strict":true},
    name: "areas-skipasses___de-DE"
  }, {
    path: "/skigebiete/top50",
    component: _741dc4ec,
    pathToRegexpOptions: {"strict":true},
    name: "areas-top50___de-DE"
  }, {
    path: "/skigebiete/ubersicht-lander-mit-wintersportgebieten",
    component: _2fef4291,
    pathToRegexpOptions: {"strict":true},
    name: "areas-countries___de-DE"
  }, {
    path: "/skigebiete/vergleichen",
    component: _4dd1e276,
    pathToRegexpOptions: {"strict":true},
    name: "areas-similar___de-DE"
  }, {
    path: "/weersneeuw/sneeuwhoogte",
    component: _2e0ce2d6,
    pathToRegexpOptions: {"strict":true},
    name: "weather-snowheight___nl-NL"
  }, {
    path: "/weersneeuw/sneeuwhoogte",
    component: _2e0ce2d6,
    pathToRegexpOptions: {"strict":true},
    name: "weather-snowheight___nl-BE"
  }, {
    path: "/wetter-schnee/schneehoehe",
    component: _2e0ce2d6,
    pathToRegexpOptions: {"strict":true},
    name: "weather-snowheight___de-DE"
  }, {
    path: "/accommodations/checkout/snowtrex",
    component: _784b0ec8,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout-snowtrex___de-DE"
  }, {
    path: "/accommodations/checkout/success",
    component: _2108ca6a,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout-success___de-DE"
  }, {
    path: "/gadget/gadget/area",
    component: _583b275a,
    pathToRegexpOptions: {"strict":true},
    name: "gadget-gadget-area___de-DE"
  }, {
    path: "/gadget/gadget/resort",
    component: _23aaa444,
    pathToRegexpOptions: {"strict":true},
    name: "gadget-gadget-resort___de-DE"
  }, {
    path: "/profile/membership/benefits",
    component: _7a9fb212,
    pathToRegexpOptions: {"strict":true},
    name: "profile-membership-benefits___de-DE"
  }, {
    path: "/profiel/inbox/conversation/nieuw",
    component: _13d2a2e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-newconversation___nl-NL"
  }, {
    path: "/profiel/inbox/conversation/nieuw",
    component: _13d2a2e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-newconversation___nl-BE"
  }, {
    path: "/profil/posteingang/konversation/neu",
    component: _13d2a2e0,
    pathToRegexpOptions: {"strict":true},
    name: "profile-newconversation___de-DE"
  }, {
    path: "/accommodatie/checkout/snowtrex/:id",
    component: _784b0ec8,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout-snowtrex___nl-NL"
  }, {
    path: "/accommodatie/checkout/snowtrex/:id",
    component: _784b0ec8,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout-snowtrex___nl-BE"
  }, {
    path: "/profiel/club/event/:event",
    component: _01fd8be6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-club-event-event___nl-NL"
  }, {
    path: "/profiel/club/event/:event",
    component: _01fd8be6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-club-event-event___nl-BE"
  }, {
    path: "/profile/club/event/:event?",
    component: _01fd8be6,
    pathToRegexpOptions: {"strict":true},
    name: "profile-club-event-event___de-DE"
  }, {
    path: "/wetter-schnee/webcams/webcam/:webcam",
    component: _372c59f4,
    pathToRegexpOptions: {"strict":true},
    name: "webcams-webcam___de-DE"
  }, {
    path: "/accommodatie/checkout/:id?",
    component: _1f3f301a,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout___nl-NL"
  }, {
    path: "/accommodatie/checkout/:id?",
    component: _1f3f301a,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout___nl-BE"
  }, {
    path: "/accommodatie/mijnreis/:mytrip",
    component: _76b7666f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/accommodatie/mijnreis/:mytrip",
      component: _10b31db2,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip___nl-NL"
    }, {
      path: "/accommodatie/mijnreis/:mytrip/ski-huur",
      component: _6a306b74,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-rental___nl-NL"
    }, {
      path: "/accommodatie/mijnreis/:mytrip/skiles",
      component: _70bb3eca,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-lessons___nl-NL"
    }, {
      path: "/accommodatie/mijnreis/:mytrip/skipas",
      component: _050f647e,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-skipas___nl-NL"
    }]
  }, {
    path: "/accommodatie/mijnreis/:mytrip",
    component: _76b7666f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/accommodatie/mijnreis/:mytrip",
      component: _10b31db2,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip___nl-BE"
    }, {
      path: "/accommodatie/mijnreis/:mytrip/ski-huur",
      component: _6a306b74,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-rental___nl-BE"
    }, {
      path: "/accommodatie/mijnreis/:mytrip/skiles",
      component: _70bb3eca,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-lessons___nl-BE"
    }, {
      path: "/accommodatie/mijnreis/:mytrip/skipas",
      component: _050f647e,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-skipas___nl-BE"
    }]
  }, {
    path: "/gadget/area/:slug",
    component: _583b275a,
    pathToRegexpOptions: {"strict":true},
    name: "gadget-gadget-area___nl-NL"
  }, {
    path: "/gadget/area/:slug",
    component: _583b275a,
    pathToRegexpOptions: {"strict":true},
    name: "gadget-gadget-area___nl-BE"
  }, {
    path: "/gadget/resort/:slug",
    component: _23aaa444,
    pathToRegexpOptions: {"strict":true},
    name: "gadget-gadget-resort___nl-NL"
  }, {
    path: "/gadget/resort/:slug",
    component: _23aaa444,
    pathToRegexpOptions: {"strict":true},
    name: "gadget-gadget-resort___nl-BE"
  }, {
    path: "/gear/features/:feature?",
    component: _18bef676,
    pathToRegexpOptions: {"strict":true},
    name: "gear-features-feature___de-DE"
  }, {
    path: "/materiaal/kenmerken/:slug",
    component: _18bef676,
    pathToRegexpOptions: {"strict":true},
    name: "gear-features-feature___nl-NL"
  }, {
    path: "/materiaal/kenmerken/:slug",
    component: _18bef676,
    pathToRegexpOptions: {"strict":true},
    name: "gear-features-feature___nl-BE"
  }, {
    path: "/profiel/magazine/:issue",
    component: _00697027,
    pathToRegexpOptions: {"strict":true},
    name: "profile-magazine___nl-NL"
  }, {
    path: "/profiel/magazine/:issue",
    component: _00697027,
    pathToRegexpOptions: {"strict":true},
    name: "profile-magazine___nl-BE"
  }, {
    path: "/skigebieden/overzicht/:country",
    component: _b3d77030,
    pathToRegexpOptions: {"strict":true},
    name: "areas-countries-country___nl-NL"
  }, {
    path: "/skigebieden/overzicht/:country",
    component: _b3d77030,
    pathToRegexpOptions: {"strict":true},
    name: "areas-countries-country___nl-BE"
  }, {
    path: "/skigebieden/regio/:region",
    component: _651d34dd,
    pathToRegexpOptions: {"strict":true},
    name: "areas-regions-region___nl-NL"
  }, {
    path: "/skigebieden/regio/:region",
    component: _651d34dd,
    pathToRegexpOptions: {"strict":true},
    name: "areas-regions-region___nl-BE"
  }, {
    path: "/skigebieden/thema/:theme",
    component: _4d148034,
    pathToRegexpOptions: {"strict":true},
    name: "areas-theme-theme___nl-NL"
  }, {
    path: "/skigebieden/thema/:theme",
    component: _4d148034,
    pathToRegexpOptions: {"strict":true},
    name: "areas-theme-theme___nl-BE"
  }, {
    path: "/skigebiete/regionen/:region",
    component: _651d34dd,
    pathToRegexpOptions: {"strict":true},
    name: "areas-regions-region___de-DE"
  }, {
    path: "/skigebiete/ubersicht-lander-mit-wintersportgebieten/:country",
    component: _b3d77030,
    pathToRegexpOptions: {"strict":true},
    name: "areas-countries-country___de-DE"
  }, {
    path: "/themen/skigebiete/:theme",
    component: _4d148034,
    pathToRegexpOptions: {"strict":true},
    name: "areas-theme-theme___de-DE"
  }, {
    path: "/weersneeuw/europe/:type",
    component: _36f27594,
    pathToRegexpOptions: {"strict":true},
    name: "weather-europe-type___nl-NL"
  }, {
    path: "/weersneeuw/europe/:type",
    component: _36f27594,
    pathToRegexpOptions: {"strict":true},
    name: "weather-europe-type___nl-BE"
  }, {
    path: "/weersneeuw/sneeuwhoogte/:country",
    component: _094f6538,
    pathToRegexpOptions: {"strict":true},
    name: "weather-snowheight-country___nl-NL"
  }, {
    path: "/weersneeuw/sneeuwhoogte/:country",
    component: _094f6538,
    pathToRegexpOptions: {"strict":true},
    name: "weather-snowheight-country___nl-BE"
  }, {
    path: "/wetter-schnee/europa/:type",
    component: _36f27594,
    pathToRegexpOptions: {"strict":true},
    name: "weather-europe-type___de-DE"
  }, {
    path: "/wetter-schnee/schneehoehe/:country",
    component: _094f6538,
    pathToRegexpOptions: {"strict":true},
    name: "weather-snowheight-country___de-DE"
  }, {
    path: "/accommodatie/checkout/:id/success",
    component: _2108ca6a,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout-success___nl-NL"
  }, {
    path: "/accommodatie/checkout/:id/success",
    component: _2108ca6a,
    pathToRegexpOptions: {"strict":true},
    name: "accommodations-checkout-success___nl-BE"
  }, {
    path: "/accommodations/:mytrip",
    component: _76b7666f,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _10b31db2,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip___de-DE"
    }, {
      path: "lessons",
      component: _70bb3eca,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-lessons___de-DE"
    }, {
      path: "rental",
      component: _6a306b74,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-rental___de-DE"
    }, {
      path: "skipas",
      component: _050f647e,
      pathToRegexpOptions: {"strict":true},
      name: "accommodations-mytrip-skipas___de-DE"
    }]
  }, {
    path: "/dorpen/:resort",
    component: _a8e99874,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/dorpen/:resort",
      component: _72f74dc9,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort___nl-NL"
    }, {
      path: "/dorpen/:resort/apres-ski",
      component: _2867d439,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-apresski___nl-NL"
    }, {
      path: "/dorpen/:resort/bereikbaarheid",
      component: _bbeaa1a0,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-reachability___nl-NL"
    }, {
      path: "/dorpen/:resort/ervaringen",
      component: _59541e5c,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-reviews___nl-NL"
    }, {
      path: "/dorpen/:resort/forum",
      component: _2251b1ca,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forum___nl-NL"
    }, {
      path: "/dorpen/:resort/kaarten",
      component: _5a73815f,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-skimaps___nl-NL"
    }, {
      path: "/dorpen/:resort/plattegrond",
      component: _7492ee02,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-plan___nl-NL"
    }, {
      path: "/dorpen/:resort/restaurants",
      component: _24ed5c8d,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-restaurants___nl-NL"
    }, {
      path: "/dorpen/:resort/ski-huur",
      component: _50723e3d,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-rental___nl-NL"
    }, {
      path: "/dorpen/:resort/skiles",
      component: _fd8d775e,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-lesson___nl-NL"
    }, {
      path: "/dorpen/:resort/skipas",
      component: _388bbeec,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-skipas___nl-NL"
    }, {
      path: "/dorpen/:resort/sneeuwhoogte",
      component: _bcc4cbfa,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-snowheight___nl-NL"
    }, {
      path: "/dorpen/:resort/sneeuwzekerheid",
      component: _b89bb648,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-snowguarantee___nl-NL"
    }, {
      path: "/dorpen/:resort/video",
      component: _1ae7861c,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-video___nl-NL"
    }, {
      path: "/dorpen/:resort/vliegveld",
      component: _4fe68182,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-airport___nl-NL"
    }, {
      path: "/dorpen/:resort/webcams",
      component: _5af75be2,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-webcams___nl-NL"
    }, {
      path: "/dorpen/:resort/weersverwachting",
      component: _dc0ba958,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forecast___nl-NL"
    }, {
      path: "/dorpen/:resort/forum/topic/:slug",
      component: _fee64792,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forum-topic___nl-NL"
    }, {
      path: "/dorpen/:resort/locatie/:location",
      component: _3f2784ea,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-location___nl-NL"
    }, {
      path: "/dorpen/:resort/:accommodationtype",
      component: _6796eb26,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-accommodationtype___nl-NL"
    }]
  }, {
    path: "/dorpen/:resort",
    component: _a8e99874,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/dorpen/:resort",
      component: _72f74dc9,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort___nl-BE"
    }, {
      path: "/dorpen/:resort/apres-ski",
      component: _2867d439,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-apresski___nl-BE"
    }, {
      path: "/dorpen/:resort/bereikbaarheid",
      component: _bbeaa1a0,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-reachability___nl-BE"
    }, {
      path: "/dorpen/:resort/ervaringen",
      component: _59541e5c,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-reviews___nl-BE"
    }, {
      path: "/dorpen/:resort/forum",
      component: _2251b1ca,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forum___nl-BE"
    }, {
      path: "/dorpen/:resort/kaarten",
      component: _5a73815f,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-skimaps___nl-BE"
    }, {
      path: "/dorpen/:resort/plattegrond",
      component: _7492ee02,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-plan___nl-BE"
    }, {
      path: "/dorpen/:resort/restaurants",
      component: _24ed5c8d,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-restaurants___nl-BE"
    }, {
      path: "/dorpen/:resort/ski-huur",
      component: _50723e3d,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-rental___nl-BE"
    }, {
      path: "/dorpen/:resort/skiles",
      component: _fd8d775e,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-lesson___nl-BE"
    }, {
      path: "/dorpen/:resort/skipas",
      component: _388bbeec,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-skipas___nl-BE"
    }, {
      path: "/dorpen/:resort/sneeuwhoogte",
      component: _bcc4cbfa,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-snowheight___nl-BE"
    }, {
      path: "/dorpen/:resort/sneeuwzekerheid",
      component: _b89bb648,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-snowguarantee___nl-BE"
    }, {
      path: "/dorpen/:resort/video",
      component: _1ae7861c,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-video___nl-BE"
    }, {
      path: "/dorpen/:resort/vliegveld",
      component: _4fe68182,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-airport___nl-BE"
    }, {
      path: "/dorpen/:resort/webcams",
      component: _5af75be2,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-webcams___nl-BE"
    }, {
      path: "/dorpen/:resort/weersverwachting",
      component: _dc0ba958,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forecast___nl-BE"
    }, {
      path: "/dorpen/:resort/forum/topic/:slug",
      component: _fee64792,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forum-topic___nl-BE"
    }, {
      path: "/dorpen/:resort/locatie/:location",
      component: _3f2784ea,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-location___nl-BE"
    }, {
      path: "/dorpen/:resort/:accommodationtype",
      component: _6796eb26,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-accommodationtype___nl-BE"
    }]
  }, {
    path: "/error/:code",
    component: _c0c9ab2a,
    pathToRegexpOptions: {"strict":true},
    name: "error___nl-NL"
  }, {
    path: "/error/:code",
    component: _c0c9ab2a,
    pathToRegexpOptions: {"strict":true},
    name: "error___de-DE"
  }, {
    path: "/error/:code",
    component: _c0c9ab2a,
    pathToRegexpOptions: {"strict":true},
    name: "error___nl-BE"
  }, {
    path: "/flughaefen/:airport",
    component: _f0c1f9d4,
    pathToRegexpOptions: {"strict":true},
    name: "airports-airport___de-DE"
  }, {
    path: "/gear/:category",
    component: _82ccf8e6,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category___de-DE"
  }, {
    path: "/materiaal/:category",
    component: _82ccf8e6,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category___nl-NL"
  }, {
    path: "/materiaal/:category",
    component: _82ccf8e6,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category___nl-BE"
  }, {
    path: "/profiel/:slug?",
    component: _cad301f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-slug___nl-NL"
  }, {
    path: "/profiel/:slug?",
    component: _cad301f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-slug___nl-BE"
  }, {
    path: "/profil/:slug?",
    component: _cad301f2,
    pathToRegexpOptions: {"strict":true},
    name: "profile-slug___de-DE"
  }, {
    path: "/skigebieden/:area",
    component: _a729bb74,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/skigebieden/:area",
      component: _e26b476e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area___nl-NL"
    }, {
      path: "/skigebieden/:area/apres-ski",
      component: _1e4c35b9,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-apresski___nl-NL"
    }, {
      path: "/skigebieden/:area/bereikbaarheid",
      component: _63e250b0,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-reachability___nl-NL"
    }, {
      path: "/skigebieden/:area/dorpen",
      component: _b8a8d00e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-resorts___nl-NL"
    }, {
      path: "/skigebieden/:area/ervaringen",
      component: _469f5f52,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-reviews___nl-NL"
    }, {
      path: "/skigebieden/:area/forum",
      component: _aa7554ca,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forum___nl-NL"
    }, {
      path: "/skigebieden/:area/kaarten",
      component: _64862042,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-skimaps___nl-NL"
    }, {
      path: "/skigebieden/:area/plattegrond",
      component: _22e40f82,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-plan___nl-NL"
    }, {
      path: "/skigebieden/:area/restaurants",
      component: _3e48e9e6,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-restaurants___nl-NL"
    }, {
      path: "/skigebieden/:area/ski-huur",
      component: _a1fe0086,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-rental___nl-NL"
    }, {
      path: "/skigebieden/:area/skiles",
      component: _406ff45e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-lesson___nl-NL"
    }, {
      path: "/skigebieden/:area/skipas",
      component: _4248e20a,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-skipas___nl-NL"
    }, {
      path: "/skigebieden/:area/sneeuwhoogte",
      component: _a020c8fa,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-snowheight___nl-NL"
    }, {
      path: "/skigebieden/:area/sneeuwzekerheid",
      component: _1cceb35c,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-snowguarantee___nl-NL"
    }, {
      path: "/skigebieden/:area/vergelijken",
      component: _7a164dbc,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-similar___nl-NL"
    }, {
      path: "/skigebieden/:area/video",
      component: _0e5f4b72,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-video___nl-NL"
    }, {
      path: "/skigebieden/:area/vliegveld",
      component: _79a01ffc,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-airport___nl-NL"
    }, {
      path: "/skigebieden/:area/webcams",
      component: _45cdc08f,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-webcams___nl-NL"
    }, {
      path: "/skigebieden/:area/weersverwachting",
      component: _f042e658,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forecast___nl-NL"
    }, {
      path: "/skigebieden/:area/forum/topic/:slug",
      component: _42647db7,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forum-topic___nl-NL"
    }, {
      path: "/skigebieden/:area/:accommodationtype",
      component: _6a8f6ca6,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-accommodationtype___nl-NL"
    }]
  }, {
    path: "/skigebieden/:area",
    component: _a729bb74,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/skigebieden/:area",
      component: _e26b476e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area___nl-BE"
    }, {
      path: "/skigebieden/:area/apres-ski",
      component: _1e4c35b9,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-apresski___nl-BE"
    }, {
      path: "/skigebieden/:area/bereikbaarheid",
      component: _63e250b0,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-reachability___nl-BE"
    }, {
      path: "/skigebieden/:area/dorpen",
      component: _b8a8d00e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-resorts___nl-BE"
    }, {
      path: "/skigebieden/:area/ervaringen",
      component: _469f5f52,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-reviews___nl-BE"
    }, {
      path: "/skigebieden/:area/forum",
      component: _aa7554ca,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forum___nl-BE"
    }, {
      path: "/skigebieden/:area/kaarten",
      component: _64862042,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-skimaps___nl-BE"
    }, {
      path: "/skigebieden/:area/plattegrond",
      component: _22e40f82,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-plan___nl-BE"
    }, {
      path: "/skigebieden/:area/restaurants",
      component: _3e48e9e6,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-restaurants___nl-BE"
    }, {
      path: "/skigebieden/:area/ski-huur",
      component: _a1fe0086,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-rental___nl-BE"
    }, {
      path: "/skigebieden/:area/skiles",
      component: _406ff45e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-lesson___nl-BE"
    }, {
      path: "/skigebieden/:area/skipas",
      component: _4248e20a,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-skipas___nl-BE"
    }, {
      path: "/skigebieden/:area/sneeuwhoogte",
      component: _a020c8fa,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-snowheight___nl-BE"
    }, {
      path: "/skigebieden/:area/sneeuwzekerheid",
      component: _1cceb35c,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-snowguarantee___nl-BE"
    }, {
      path: "/skigebieden/:area/vergelijken",
      component: _7a164dbc,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-similar___nl-BE"
    }, {
      path: "/skigebieden/:area/video",
      component: _0e5f4b72,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-video___nl-BE"
    }, {
      path: "/skigebieden/:area/vliegveld",
      component: _79a01ffc,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-airport___nl-BE"
    }, {
      path: "/skigebieden/:area/webcams",
      component: _45cdc08f,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-webcams___nl-BE"
    }, {
      path: "/skigebieden/:area/weersverwachting",
      component: _f042e658,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forecast___nl-BE"
    }, {
      path: "/skigebieden/:area/forum/topic/:slug",
      component: _42647db7,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forum-topic___nl-BE"
    }, {
      path: "/skigebieden/:area/:accommodationtype",
      component: _6a8f6ca6,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-accommodationtype___nl-BE"
    }]
  }, {
    path: "/skigebiete/:area",
    component: _a729bb74,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/skigebiete/:area",
      component: _e26b476e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area___de-DE"
    }, {
      path: "/skigebiete/:area/apres-ski",
      component: _1e4c35b9,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-apresski___de-DE"
    }, {
      path: "/skigebiete/:area/erfahrungen",
      component: _469f5f52,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-reviews___de-DE"
    }, {
      path: "/skigebiete/:area/erreichbarkeit",
      component: _63e250b0,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-reachability___de-DE"
    }, {
      path: "/skigebiete/:area/flughafen",
      component: _79a01ffc,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-airport___de-DE"
    }, {
      path: "/skigebiete/:area/forum",
      component: _aa7554ca,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forum___de-DE"
    }, {
      path: "/skigebiete/:area/lageplan",
      component: _22e40f82,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-plan___de-DE"
    }, {
      path: "/skigebiete/:area/pistenkarten",
      component: _64862042,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-skimaps___de-DE"
    }, {
      path: "/skigebiete/:area/restaurants",
      component: _3e48e9e6,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-restaurants___de-DE"
    }, {
      path: "/skigebiete/:area/schneehoehe",
      component: _a020c8fa,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-snowheight___de-DE"
    }, {
      path: "/skigebiete/:area/schneesicherheit",
      component: _1cceb35c,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-snowguarantee___de-DE"
    }, {
      path: "/skigebiete/:area/skiles",
      component: _406ff45e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-lesson___de-DE"
    }, {
      path: "/skigebiete/:area/skiorte",
      component: _b8a8d00e,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-resorts___de-DE"
    }, {
      path: "/skigebiete/:area/skipass",
      component: _4248e20a,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-skipas___de-DE"
    }, {
      path: "/skigebiete/:area/skiverleih",
      component: _a1fe0086,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-rental___de-DE"
    }, {
      path: "/skigebiete/:area/vergleichen",
      component: _7a164dbc,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-similar___de-DE"
    }, {
      path: "/skigebiete/:area/video",
      component: _0e5f4b72,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-video___de-DE"
    }, {
      path: "/skigebiete/:area/webcams",
      component: _45cdc08f,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-webcams___de-DE"
    }, {
      path: "/skigebiete/:area/wettervorhersage",
      component: _f042e658,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forecast___de-DE"
    }, {
      path: "/skigebiete/:area/forum/topic/:slug",
      component: _42647db7,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-forum-topic___de-DE"
    }, {
      path: "/skigebiete/:area/:accommodationtype",
      component: _6a8f6ca6,
      pathToRegexpOptions: {"strict":true},
      name: "areas-area-accommodationtype___de-DE"
    }]
  }, {
    path: "/skiorte/:resort",
    component: _a8e99874,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/skiorte/:resort",
      component: _72f74dc9,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort___de-DE"
    }, {
      path: "/skiorte/:resort/apres-ski",
      component: _2867d439,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-apresski___de-DE"
    }, {
      path: "/skiorte/:resort/erfahrungen",
      component: _59541e5c,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-reviews___de-DE"
    }, {
      path: "/skiorte/:resort/erreichbarkeit",
      component: _bbeaa1a0,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-reachability___de-DE"
    }, {
      path: "/skiorte/:resort/flughafen",
      component: _4fe68182,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-airport___de-DE"
    }, {
      path: "/skiorte/:resort/forum",
      component: _2251b1ca,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forum___de-DE"
    }, {
      path: "/skiorte/:resort/lageplan",
      component: _7492ee02,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-plan___de-DE"
    }, {
      path: "/skiorte/:resort/pistenkarten",
      component: _5a73815f,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-skimaps___de-DE"
    }, {
      path: "/skiorte/:resort/restaurants",
      component: _24ed5c8d,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-restaurants___de-DE"
    }, {
      path: "/skiorte/:resort/schneehoehe",
      component: _bcc4cbfa,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-snowheight___de-DE"
    }, {
      path: "/skiorte/:resort/schneesicherheit",
      component: _b89bb648,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-snowguarantee___de-DE"
    }, {
      path: "/skiorte/:resort/skiles",
      component: _fd8d775e,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-lesson___de-DE"
    }, {
      path: "/skiorte/:resort/skipass",
      component: _388bbeec,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-skipas___de-DE"
    }, {
      path: "/skiorte/:resort/skiverleih",
      component: _50723e3d,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-rental___de-DE"
    }, {
      path: "/skiorte/:resort/videos",
      component: _1ae7861c,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-video___de-DE"
    }, {
      path: "/skiorte/:resort/webcams",
      component: _5af75be2,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-webcams___de-DE"
    }, {
      path: "/skiorte/:resort/wettervorhersage",
      component: _dc0ba958,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forecast___de-DE"
    }, {
      path: "/skiorte/:resort/forum/topic/:slug",
      component: _fee64792,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-forum-topic___de-DE"
    }, {
      path: "/skiorte/:resort/lokalitaet/:location",
      component: _3f2784ea,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-location___de-DE"
    }, {
      path: "/doerfer/:resort/:accommodationtype",
      component: _6796eb26,
      pathToRegexpOptions: {"strict":true},
      name: "resorts-resort-accommodationtype___de-DE"
    }]
  }, {
    path: "/skipas/:cluster",
    component: _66e7ffb4,
    pathToRegexpOptions: {"strict":true},
    name: "clusters-cluster___nl-NL"
  }, {
    path: "/skipas/:cluster",
    component: _66e7ffb4,
    pathToRegexpOptions: {"strict":true},
    name: "clusters-cluster___de-DE"
  }, {
    path: "/skipas/:cluster",
    component: _66e7ffb4,
    pathToRegexpOptions: {"strict":true},
    name: "clusters-cluster___nl-BE"
  }, {
    path: "/vliegvelden/:airport",
    component: _f0c1f9d4,
    pathToRegexpOptions: {"strict":true},
    name: "airports-airport___nl-NL"
  }, {
    path: "/vliegvelden/:airport",
    component: _f0c1f9d4,
    pathToRegexpOptions: {"strict":true},
    name: "airports-airport___nl-BE"
  }, {
    path: "/webcams/:webcam",
    component: _372c59f4,
    pathToRegexpOptions: {"strict":true},
    name: "webcams-webcam___nl-NL"
  }, {
    path: "/webcams/:webcam",
    component: _372c59f4,
    pathToRegexpOptions: {"strict":true},
    name: "webcams-webcam___nl-BE"
  }, {
    path: "/weersneeuw/:type",
    component: _4520d0a1,
    pathToRegexpOptions: {"strict":true},
    name: "weather-type___nl-NL"
  }, {
    path: "/weersneeuw/:type",
    component: _4520d0a1,
    pathToRegexpOptions: {"strict":true},
    name: "weather-type___nl-BE"
  }, {
    path: "/wetter-schnee/:type",
    component: _4520d0a1,
    pathToRegexpOptions: {"strict":true},
    name: "weather-type___de-DE"
  }, {
    path: "/gear/:category/product/:product?",
    component: _c616eb36,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category-product-product___de-DE"
  }, {
    path: "/materiaal/:category/product/:product",
    component: _c616eb36,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category-product-product___nl-NL"
  }, {
    path: "/materiaal/:category/product/:product",
    component: _c616eb36,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category-product-product___nl-BE"
  }, {
    path: "/gear/:category/:cluster",
    component: _5ea70460,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category-cluster___de-DE"
  }, {
    path: "/materiaal/:category/:cluster",
    component: _5ea70460,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category-cluster___nl-NL"
  }, {
    path: "/materiaal/:category/:cluster",
    component: _5ea70460,
    pathToRegexpOptions: {"strict":true},
    name: "gear-category-cluster___nl-BE"
  }, {
    path: "/",
    component: _5bd50c35,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl-NL"
  }, {
    path: "/",
    component: _5bd50c35,
    pathToRegexpOptions: {"strict":true},
    name: "index___de-DE"
  }, {
    path: "/",
    component: _5bd50c35,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl-BE"
  }, {
    path: "/*",
    component: _17367a94,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/*",
      component: _41c5e8b9,
      pathToRegexpOptions: {"strict":true},
      name: "contentpage___nl-BE"
    }]
  }, {
    path: "/*",
    component: _17367a94,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/*",
      component: _41c5e8b9,
      pathToRegexpOptions: {"strict":true},
      name: "contentpage___de-DE"
    }]
  }, {
    path: "/*",
    component: _17367a94,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "/*",
      component: _41c5e8b9,
      pathToRegexpOptions: {"strict":true},
      name: "contentpage___nl-NL"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
