<template lang="pug">
dnt(thirdParty="vimeo" thirdPartyName="vimeo.com")
	lite-vimeo(:videoid="vimeoId")
		.ltv-playbtn
</template>

<script>
import gql from 'graphql-tag';
import dnt from '@dengl/common/components/dnt';
import fields from './fields';

export default {
	name: 'VimeoEmbed',
	components: { dnt },
	props: fields,

	mounted() {
		if (!window.LiteVimeoEmbed) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.async = false;
			script.crossorigin = 'anonymous';
			script.src =
				'//unpkg.com/lite-vimeo-embed@0.3.0/lite-vimeo-embed.js';
			document.body.append(script);
			window.LiteVimeoEmbed = true;
		}
	},
	fragments: gql`
		fragment VimeoEmbedDataFragment on Embed {
			data {
				... on EmbedDataVimeo {
					vimeoId
				}
			}
		}
	`
};
</script>
