
import YoutubeEmbed from '@dengl/embeds/youtube/embed.vue';
import VimeoEmbed from '@dengl/embeds/vimeo/embed.vue';

export default {
	render(createElement) {

		console.warn('usage of vue-plyr is deprecated');

		if (this.$slots.default[0].data.attrs['data-plyr-provider'] === 'youtube') {
			return createElement(YoutubeEmbed, { props: { 'youtube-id': this.$slots.default[0].data.attrs['data-plyr-embed-id'] } });
		}
		if (this.$slots.default[0].data.attrs['data-plyr-provider'] === 'vimeo') {
			return createElement(VimeoEmbed, { props: { 'vimeo-id': this.$slots.default[0].data.attrs['data-plyr-embed-id'] } });
		}

		return createElement('div', {});
	},
	name: 'VuePlyr'
};
