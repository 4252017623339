<template lang="pug">
v-app-bar#main-navigation(:height="76" :extension-height="98" :absolute="isAbsolute && !this.$vuetify.breakpoint.xs" :scroll-off-screen="$vuetify.breakpoint.xs" elevate-on-scroll :hide-on-scroll="$vuetify.breakpoint.xs" :scroll-threshold="100" :app="(isAbsolute && $vuetify.breakpoint.xs) || ($vuetify.breakpoint.xs && mounted)")
	template(#extension v-if="showMenuExtension")
		v-dialog(content-class="mobile-menu" fullscreen transition="dialog-top-transition" :value="showFullMenu" scrollable)
			v-card
				v-list(flat expand subheader)
					navigation-group-mobile(:to="localePath({name: 'areas'})" :items="navigationItems['areas']") skigebieden
					v-divider(color="black")
					navigation-group-mobile(:to="localePath({name: 'weblog'})" :items="navigationItems['inspiration']") inspiratie
					v-divider(color="black")
					navigation-group-mobile(:to="localePath({name: 'weather'})") weer
					v-divider(color="black")
					navigation-group-mobile(:to="localePath({name: 'accommodations'})") accommodatie
					v-divider(color="black")
					navigation-group-mobile(v-if="$i18n.locale === 'nl-NL'" :to="localePath({name: 'verzekering'})" :items="navigationItems['nskiv']" color="accent") verzekering
					v-divider(color="black")
					navigation-group-mobile(:to="localePath({name: 'forum-category'})" color="accent") forum
					v-divider(color="black")
				//- navigation-group(:to="localePath({name: 'forum-category'})" color="accent") {{username}}
					span.notifier(v-if="$store.getters.forumNotifications.length") {{$store.getters.forumNotifications.length}}
		v-container.hidden-md-and-up
			top-navigation-search.search-bar--mobile(v-if="showMobileNav" @toggle="toggleMobileNav")
			v-row.mobile-main-nav
				v-col.pa-0
					nuxt-link(:to="localePath({name: 'areas'})") skigebieden
				v-divider.mx-0(color="white" vertical)
				v-col.pa-0
					nuxt-link(:to="localePath({name: 'weather'})") weer
				v-divider.mx-0(color="white" vertical)
				v-col.pa-0
					nuxt-link(:to="localePath({name: 'forum'})") forum
						span.notifier(v-if="$store.getters.forumNotifications.length") {{$store.getters.forumNotifications.length}}
				v-divider.mx-0(color="white" vertical)
				v-col.pa-0.text-no-wrap( @click="$store.commit('TOGGLE_FULLMENU')")
					| meer
					v-icon(small color="white") {{showFullMenu ? 'arrow_drop_up' : 'arrow_drop_down'}}

			v-row
				v-toolbar.text-center(flat dense color="white")
					v-app-bar-nav-icon(@click="$emit('shownav')" v-if="$vuetify.breakpoint.xsOnly")
						portal-target(name="drawer-nav-icon")
							v-icon menu
					v-toolbar-title.subtitle-2.font-weight-bold {{title}}
	.mobile-navigation-top.justify-md-start.justify-space-between
		nuxt-link.hidden-md-and-up(:to="localePath({name: 'profile-slug'})" style="position: relative;")
			v-avatar
				v-img(v-if="avatarUrl" :src="`${avatarUrl}?width=160`")
				v-img(v-else src="https://static.wintersport.nl/images/avatar-w48.png")
			span.notifier(v-if="$store.getters.inboxNotifications.length") {{$store.getters.inboxNotifications.length}}
		nuxt-link.logo(:to="localePath({name: 'index'})")
			img(:src="logoUrl" alt="Wintersport.nl" height="40" width="183")
		top-navigation-search.ml-6.mt-1.hidden-xs-only
		v-btn.mt-3.hidden-sm-and-up(@click="toggleMobileNav" color="accent" depressed icon small)
			v-icon search
	v-spacer.hidden-sm-and-down
	.hidden-sm-and-down
		navigation-group(:to="localePath({name: 'areas'})" :items="navigationItems['areas']") skigebieden
		navigation-group(:to="localePath({name: 'weblog'})" :items="navigationItems['inspiration']") inspiratie
		navigation-group(:to="localePath({name: 'weather'})") weer
		navigation-group(:to="localePath({name: 'accommodations'})") accommodatie
		navigation-group(v-if="$i18n.locale === 'nl-NL'" :to="localePath({name: 'verzekering'})" :items="navigationItems['nskiv']" color="accent") verzekering
		navigation-group(:to="localePath({name: 'forum-category'})" color="accent") forum
			span.notifier(v-if="$store.getters.forumNotifications.length") {{$store.getters.forumNotifications.length}}
		.d-inline-block.ml-2
			navigation-group(:to="localePath({name: 'profile-slug'})" :items="profileMenu" color="accent")
				template(#button="{ on, attrs }")
					v-btn.font-weight-bold.btn--profile(v-on="on" depressed v-if="!user")
						| mijn wintersport
						v-icon.mr-n2 arrow_drop_down
					v-btn.font-weight-bold.px-2.btn--profile(v-else v-bind="attrs" :to="localePath('profile-slug')" v-on="on" depressed)
						| {{username}}
						v-icon.mr-n2 arrow_drop_down
						span.notifier(v-if="$store.getters.inboxNotifications.length") {{$store.getters.inboxNotifications.length}}
				template(#list)
					v-sheet(color="info")
						v-list-item(v-if="user.isAdmin" href="/admin" )
							v-list-item-icon.mr-3
								v-icon(small color="primary") shield
							v-list-item-title.body-2.primary--text Admin
						v-list-item(nuxt @click="onLogout" v-if="loggedinUser" )
							v-list-item-icon.mr-3
								v-icon(small color="primary") logout
							v-list-item-title.body-2.primary--text Uitloggen

</template>

<style lang="sass">
.mobile-menu.v-dialog--fullscreen
		top: 127px
		bottom: 0px
		height: auto

.mobile-main-nav
	background-color: #000
	height: 50px
	.theme--dark.v-divider
		border-color: rgba(255, 255, 255, 0.5)
	div
		a
			color: #fff
	.col
		color: white
		display: block
		font-weight: 700
		text-align: center
		// border-left: 1px solid white
		font-size: 11px
		line-height: 50px

		&:first-child
			border-left: none

.mobile-navigation-top
	padding: 14px 16px
	display: flex
	@media #{map-get($display-breakpoints, 'sm-and-down')}
		width: 100%
	a.logo
		@media #{map-get($display-breakpoints, 'sm-only')}
			margin-left: 150px
	.v-avatar
		border: 1px solid #4aa3df
	.notifier
		top: 5px
		right: 0

.search-bar--mobile
	position: absolute
	left: 0

#main-navigation
	background-color: hsla(0,0%,100%,.9)
	z-index: 2
	> *
		@extend %window-width
	// hack to remove the whole subnav on scroll
	&.v-app-bar--is-scrolled
		// margin-top: 48px !important

	@media #{map-get($display-breakpoints, 'xs-only')}
		border-bottom: 1px solid var(--v-border-base) !important
		box-shadow: none !important

	.v-btn--active:before
		opacity: 0

	&:after
		// @media #{map-get($display-breakpoints, 'sm-and-up')}
		width: 100%
		bottom: 0
		height: 2px
		background: linear-gradient(-248deg, red, #cc1972)
		content: ''
		display: table
		clear: both

.notifier
	position: absolute
	top: -13px
	right: -16px
	font-size: 10px
	width: 18px
	height: 18px
	line-height: 15px
	text-align: center
	color: white
	border: 2px solid white
	background: $ws-gradient
	border-radius: 50%

	@media #{map-get($display-breakpoints, 'sm-and-down')}
		top: 5px
		right: 0

.v-btn.btn--profile
	color: var(--v-accent-base) !important
	background-color: #4aa3df26 !important
</style>

<script>
import NavigationGroup from './navigation-group.vue';
import TopNavigationSearch from './top-navigation-search';
import searchbar from '@/components/searchbar.vue';
const NavigationGroupMobile = () =>
	import(
		/* webpackChunkName: "NavigationGroupMobile" */ './navigation-group.mobile.vue'
	);

export default {
	name: 'MainNav',
	components: {
		searchbar,
		NavigationGroup,
		NavigationGroupMobile,
		TopNavigationSearch
	},
	props: {
		title: {
			type: String,
			default: 'wintersport.nl'
		}
	},
	data() {
		return {
			showMobileNav: false,
			mounted: false,
			navigationItems: {
				areas: [
					{ text: 'Skigebieden' },
					{
						text: 'Alle skigebieden',
						path: {
							name: 'areas'
						}
					},
					{
						text: 'Skigebieden Oostenrijk',
						path: {
							name: 'areas-countries-country',
							params: { country: 'oostenrijk' }
						}
					},
					{
						text: 'Skigebieden Frankrijk',
						path: {
							name: 'areas-countries-country',
							params: { country: 'frankrijk' }
						}
					},
					{
						text: 'Skigebieden Zwitserland',
						path: {
							name: 'areas-countries-country',
							params: { country: 'zwitserland' }
						}
					},
					{
						text: 'Skigebieden Italië',
						path: {
							name: 'areas-countries-country',
							params: { country: 'italie' }
						}
					},
					'---',
					{
						text: 'Skigebieden vergelijken',
						path: {
							name: 'areas-similar'
						}
					},
					'---',
					{ text: 'In de buurt' },
					{
						text: 'Skireizen in Nederland',
						path: {
							name: 'trips'
						}
					},
					{ text: 'Skihallen', path: { name: 'localdestinations-skihalls' } },
					{
						text: 'Outdoorbanen',
						path: { name: 'localdestinations-dryslopes' }
					},
					{
						text: 'Indoor rolbanen',
						path: { name: 'localdestinations-revolvingslopes' }
					},
					{
						text: 'Alle opties in de buurt',
						path: { name: 'localdestinations' }
					}
				],
				inspiration: [
					{ text: 'Inspiratie' },
					{ text: 'Weblog', path: { name: 'weblog' } },
					{ text: 'Nieuws', path: { name: 'news' } },
					{
						text: "Thema's",
						path: { name: 'contentpage', params: { 0: 'themas' } }
					},
					{ text: 'Skitest', url: 'https://skitest.wintersport.nl/' },
					{ text: 'Wintersport Academy', path: { name: 'academy' } },
					'---',
					{ text: 'Kanalen' },
					{ text: 'Snow Show', path: { name: 'videos-snowshow' } },
					{ text: 'Podcast', path: { name: 'podcast' } },
					{ text: 'Video', path: { name: 'videos' } },
					{ text: 'Nieuwsbrief', path: { name: 'newsletter' } },
					'---',
					{ text: 'Shop' },
					{
						text: 'Kleding',
						url: 'https://shop.wintersport.nl/collections/kleding'
					},
					{
						text: 'Tickets',
						url: 'https://shop.wintersport.nl/collections/tickets'
					},
					{
						text: 'Boeken',
						url: 'https://shop.wintersport.nl/collections/drukwerk-boeken'
					}
				],
				weather: [
					{ text: 'Live' },
					{ text: 'Weerbericht', path: { name: 'weather' } },
					{ text: 'Sneeuwhoogte', path: { name: 'weather-snowheight' } },
					{ text: 'Webcams', path: { name: 'weather-webcams' } },
					'---',
					{ text: 'Weerkaarten' },
					{
						text: 'Sneeuwval',
						path: { name: 'weather-type', params: { type: 'sneeuwval' } }
					},
					{
						text: 'Sneeuwradar',
						path: { name: 'weather-type', params: { type: 'sneeuwradar' } }
					},
					{
						text: 'Zon',
						path: { name: 'weather-type', params: { type: 'zon' } }
					},
					{
						text: 'Temperatuur',
						path: { name: 'weather-type', params: { type: 'temperatuur' } }
					},
					{
						text: 'Wind',
						path: { name: 'weather-type', params: { type: 'wind' } }
					}
				],
				nskiv: [
					{
						text: 'Reisverzekering',
						path: { name: 'verzekering' }
					},
					{
						text: 'Schade melden',
						path: { name: 'verzekering-profiel' }
					},
					{ text: 'Mijn verzekering', path: { name: 'verzekering-profiel' } },
					{
						text: 'Zorgverzekering',
						path: { name: 'verzekering-zorgverzekering' }
					},
					{
						text: 'Verzekering voor skileraren',
						path: {
							name: 'verzekering-slug',
							params: { slug: 'clausule-voor-seizoenswerk' }
						}
					}
				],
				community: [{ text: 'Forum', path: { name: 'forum-category' } }]
			}
		};
	},
	computed: {
		loggedinUser() {
			return this.$store.state.user.id || false;
		},
		userWithProfile() {
			return (this.loggedinUser && this.$store.state.user.profile) || false;
		},
		user() {
			return (
				(this.$store.state.user.username && this.$store.state.user) || false
			);
		},
		username() {
			return this.$store.state.user.username || '';
		},
		showFullMenu() {
			return this.$store.state.showFullMenu;
		},
		userId() {
			return this.$store.state.user.id || '';
		},
		avatarUrl() {
			return this.$store.state.user.avatar;
		},
		profileUrl() {
			return `/profiel/${this.user.id}`;
		},
		showMenuExtension() {
			return this.mounted && !this.$vuetify.breakpoint.mdAndUp;
		},
		profileMenu() {
			if (!this.loggedinUser) {
				return [
					{
						text: 'Login',
						icon: 'login',
						path: {
							name: 'login'
						}
					},
					{
						text: 'Lid worden',
						icon: 'group',
						path: {
							name: 'memberships'
						}
					}
				];
			}

			if (!this.userWithProfile) {
				return [
					{
						text: 'Profiel aanmaken',
						icon: 'person',
						path: {
							name: 'createprofile'
						}
					},
					['club', 'nskiv'].includes(this.$store.getters.membershipType)
						? {
								text: 'Mijn lidmaatschap',
								icon: 'group',
								path: {
									name: 'profile-membership'
								}
						  }
						: {
								text: 'Lid worden',
								icon: 'group',
								path: {
									name: 'memberships'
								}
						  },
					['club', 'nskiv'].includes(this.$store.getters.membershipType)
						? {
								text: 'Ledenvoordelen',
								icon: 'discount',
								path: {
									name: 'profile-membership-benefits'
								}
						  }
						: null
				].filter(Boolean);
			}

			return [
				{
					text: 'Profiel',
					icon: 'person',
					path: {
						name: 'profile-slug',
						params: { slug: this.user.slug }
					}
				},
				{
					text: 'Mijn lidmaatschap',
					icon: 'group',
					path: {
						name: 'profile-membership'
					}
				},
				['club', 'nskiv'].includes(this.$store.getters.membershipType)
					? {
							text: 'Ledenvoordelen',
							icon: 'discount',
							path: {
								name: 'profile-membership-benefits'
							}
					  }
					: null,
				{
					text: this.$store.getters.inboxNotifications.length
						? `Inbox (${this.$store.getters.inboxNotifications.length})`
						: 'Inbox',
					icon: 'inbox',
					path: { name: 'profile-inbox' }
				},
				{
					text: 'Instellingen',
					icon: 'settings',
					path: { name: 'profile-settings' }
				}
			].filter(Boolean);
		},
		isAbsolute() {
			return this.$route.path !== '/';
		},
		logoUrl() {
			return `https://static.wintersport.nl/images/${this.$i18n.locale
				.slice(3)
				.toLowerCase()}/logo.svg`;
		}
	},
	mounted() {
		this.mounted = true;
	},
	methods: {
		async onLogout() {
			await this.$apolloHelpers.onLogout(this.$i18n.locale.slice(0, 2));
			await this.$store.dispatch('checkSession');

			window.location.reload();
		},
		onEnter() {
			this.$router.push(
				this.localePath({
					name: 'search',
					query: { q: '' }
				})
			);
		},
		toggleMobileNav() {
			this.showMobileNav = !this.showMobileNav;
		},
		selectSearch(item) {
			if (!item) return false;

			const toConfig = {
				Area: { name: 'areas-area', params: { area: item.slug } },
				Resort: { name: 'resorts-resort', params: { resort: item.slug } },
				Cluster: {
					name: 'clusters-cluster',
					params: {
						cluster: item.slug
					}
				},
				Organisation: {
					name: 'localdestinations-organisation',
					params: {
						organisation: item.slug
					}
				},
				Skihall: {
					name: 'localdestinations-skihalls-slug',
					params: {
						slug: item.slug
					}
				},
				Revolvingslope: {
					name: 'localdestinations-revolvingslopes-slug',
					params: {
						slug: item.slug
					}
				},
				Dryslope: {
					name: 'localdestinations-dryslopes-slug',
					params: {
						slug: item.slug
					}
				}
			};
			const to = toConfig[item.__typename];

			if (to) {
				this.$router.push(this.localePath(to));
			}
		},

		selectSearchEnter(term) {
			this.$router.push(
				this.localePath({
					name: 'search',
					query: { q: term }
				})
			);
		}
	}
};
</script>
