<template lang="pug">
div
	template(v-if="isWintersportVideo")
		div.video-wrapper
			video.video-hls(ref="video-hls" :poster="poster" width="100%" preload="auto" :loop="loop" :muted="muted" :autoplay="autoplay" crossorigin playsinline @click="togglePlay" :controls="controls" @mouseover="controls = true" @mouseleave="controls = false")
			div.video-play-pause(v-if="showPlayButton" @click="play")
	template(v-else)
		client-only
			mave-player(:embed="videoUrl" :poster="poster" :theme="theme" :controls="controls" :autoplay="maveAutoplay" :loop="loop" :muted="muted" :subtitles="subtitles" aspect-ratio="auto" style="width: 100%;")
				//- div(slot="hover-screen")
				//- 	a(href="https://link.wintersport.nl/a81f6f019c" target="_blank" style="width: 100%; padding: 3%; background: #ffffff; border-radius: 64px; display: flex; flex-direction: column; justify-content: center; align-items: center; align-content: center;")
				//- 		div(style="line-height: 1.6rem; text-align: center;") Het ideale hotel voor je vakantie in Zwitserland
</template>

<script>
import Hls from 'hls.js';

export default {
	name: 'VideoHls',

	props: {
		videoUrl: {
			type: String,
			required: true
		},
		autoplay: {
			type: Boolean,
			default: false
		},
		muted: {
			type: Boolean,
			default: false
		},
		loop: {
			type: Boolean,
			default: false
		},
		theme: {
			type: String,
			default: 'synthwave'
		},
		subtitles: {
			type: String,
			default: 'nl'
		},
		poster: {
			type: String,
			default: '3'
		},
		controls: {
			type: String,
			default: 'full'
		}
	},
	data: () => ({
		video: null,
		showPlayButton: true
	}),
	computed: {
		isSafari() {
			return (
				/constructor/i.test(window.HTMLElement) ||
				(function (p) {
					return p.toString() === '[object SafariRemoteNotification]';
					// eslint-disable-next-line no-undef
				})(!window.safari || safari.pushNotification)
			);
		},
		isWintersportVideo() {
			return this.videoUrl.includes('video.wintersport.nl/hls');
		},
		maveAutoplay() {
			return this.autoplay ? 'always' : false;
		}
	},
	mounted() {
		if (this.isWintersportVideo) {
			this.video = this.$refs['video-hls'];
			const videoUrl = `${this.videoUrl}#t=0.1`;

			if (Hls.isSupported()) {
				const hls = new Hls({
					debug: false
				});
				hls.loadSource(videoUrl);
				hls.attachMedia(this.video);
				hls.on(Hls.Events.MEDIA_ATTACHED, () => {
					if (this.autoplay) {
						this.showPlayButton = false;
						this.video.play();
					}
				});
			} else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
				this.video.src = videoUrl;
			} else {
				console.log('No HLS support');
			}
		} else {
			const maveScript = document.createElement('script');
			maveScript.setAttribute('type', 'module');
			maveScript.setAttribute(
				'src',
				'https://cdn.jsdelivr.net/npm/@maveio/components@0.0.142/+esm'
			);
			document.head.appendChild(maveScript);
		}
	},

	methods: {
		togglePlay() {
			// Safari has click to play/pause on video built in in the video player
			if (!this.isSafari) {
				if (this.video.paused) {
					this.video.play();
				} else {
					this.video.pause();
				}
			}
		},
		play() {
			this.showPlayButton = false;
			this.video.play();
		}
	}
};
</script>

<style lang="sass">
.video-hls
	width: 100%

.video-wrapper
	display: table
	width: auto
	position: relative
	width: 100%

.video-play-pause
	background-image: url(https://static.wintersport.nl/images/video-play.png)
	background-repeat: no-repeat
	width: 50%
	height: 50%
	position: absolute
	left: 0%
	right: 0%
	top: 0%
	bottom: 0%
	margin: auto
	background-size: contain
	background-position: center
</style>
