<template lang="pug">
v-card.snow-alarm(outlined :class="{'snow-alarm--horizontal': horizontal}")
	v-img.heading(v-if="!horizontal && !$vuetify.breakpoint.mdAndDown" src="https://static.wintersport.nl/images/snow_alert.jpg" height="80" gradient="-180deg,hsla(0,0%,93%,0) 16%,rgba(15,44,77,.87)")
		.text-center.display-1.font-weight-bold.white--text {{ $t('global.snowalarm.heading') }}
	v-card-title(v-else-if="$vuetify.breakpoint.mdAndDown") {{ $t('global.snowalarm.heading') }}
	v-card-text
		validation-observer(v-slot="{ handleSubmit }" ref="form")
			v-form(@submit.prevent="handleSubmit(onSubmit)" v-if="!submitted")
				p.text--primary {{ $t('global.snowalarm.intro') }}
				v-container.pa-0
					v-row(:dense="horizontal")
						v-col(v-bind="colprops")
							v-autocomplete(
								v-model="destinationId"
								filled
								dense
								background-color="grey lighten-5"
								:items="entries"
								:loading="isLoading"
								:search-input.sync="search"
								hide-no-data
								item-text="name"
								item-value="id"
								:label="$t('global.snowalarm.form.destination')"
								persistent-placeholder
								:placeholder="$t('global.snowalarm.form.destination.placeholder')"
								clearable
								hide-details
							)
								template(#item="data")
									v-list-item-avatar(v-if="data.item.country.countryCode" size="20" tile)
										img.flag.ma-0(:src="`https://static.wintersport.nl/images/flags/svg/${data.item.country.countryCode}.svg`" width="16")
									v-list-item-content {{data.item.name}}
									v-list-item-avatar(size="20")
										ws-icon(small) {{icons[data.item.__typename]}}

						template(v-if="destinationId || !$vuetify.breakpoint.mdAndDown")
							v-col(v-bind="colprops")
								v-text-field(readonly dense filled background-color="grey lighten-5" hide-details :label="$t('global.snowalarm.form.departure')" @click="modal = true" v-model="selectedDate" clearable persistent-placeholder :placeholder="$t('global.snowalarm.form.departure.optional')")
									template(#append)
										ws-icon(@click="modal = true") calendar
								ws-dialog(ref="dialog" v-model="modal" :title="$t('global.snowalarm.form.departure')")
									v-row(no-gutters)
										v-col.shrink(v-for="month in $store.getters.seasonMonths" :key="month")
											v-date-picker.snow-alarm--datepicker.elevation-0(v-model="selectedDate" width="250" :picker-date="month" first-day-of-week="1" no-title  @input="modal = false" :locale="$i18n.locale")

							v-col(v-bind="colprops")
								validation-provider(v-slot="{errors, touched}" rules="email|required" name="email" slim)
									v-text-field(filled dense background-color="grey lighten-5" type="text" hide-details="auto" v-model="email" persistent-placeholder :label="$t('global.snowalarm.form.email')" :error-messages="touched ? errors : []")
					v-row
						v-col
							.text-right
								v-btn.gradient(type="submit" depressed :disabled="!destinationId || !email || submitting") {{ $t('global.snowalarm.form.submit') }}
				p.error(v-if="errorMessage") {{errorMessage}}
			div(v-if="submitted")
				v-img(src="https://static.wintersport.nl/images/misc/snowalarm.gif")
				.alert.alert-notification.mt-2.mb-0 {{ $t('global.snowalarm.form.submit.message.{destination}', {destination: search}) }}
</template>

<script>
import gql from 'graphql-tag';

export default {
	name: 'SnowAlarm',
	components: {},
	props: {
		initialDestinationId: {
			type: String,
			default: null,
			required: false
		},
		initialDestinationType: {
			type: String,
			default: null,
			required: false
		},
		initialDestinationName: {
			type: String,
			default: null,
			required: false
		},
		date: {
			type: String,
			default: null,
			required: false
		},
		horizontal: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			destinationId: null,
			destinationName: '',
			destinationType: '',
			selectedDate: null,
			email: null,
			submitted: false,
			submitting: false,
			errorMessage: null,
			entries: [],
			isLoading: false,
			search: null,
			modal: false,
			icons: {
				Area: 'ski',
				Resort: 'village'
			}
		};
	},
	computed: {
		minDate() {
			const now = this.$dayjs();
			let seasonStart = this.$dayjs('2022-12-01');
			if (now.isAfter(seasonStart)) seasonStart = now;
			return seasonStart.format('YYYY-MM-DD');
		},
		colprops() {
			if (
				!this.horizontal ||
				(this.isMounted && this.$vuetify.breakpoint.mdAndDown)
			) {
				return {
					cols: 12
				};
			}
			return { cols: 12, lg: 4 };
		}
	},
	watch: {
		search(value) {
			// Items have already been loaded
			if (this.entries.length > 1 || value === this.destinationName) return;
			this.performSearch();
		}
	},
	created() {
		if (this.initialDestinationId) {
			this.destinationId = this.initialDestinationId;
			this.destinationType = this.initialDestinationType;
			this.destinationName = this.initialDestinationName;

			this.entries = [
				{
					id: this.destinationId,
					destination_type: this.destinationType,
					name: this.destinationName
				}
			];
		}
		if (this.date) {
			this.selectedDate = this.date;
		}
		if (this.$store.state.user.email) {
			this.email = this.$store.state.user.email;
		}
	},
	methods: {
		performSearch() {
			if (this.isLoading) return;
			this.isLoading = true;

			const searchQuery = gql`
				query search {
					areas {
						id
						name
						slug
						country {
							id
							countryCode
						}
					}
					resorts {
						nodes {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
					}
				}
			`;

			// Lazily load input items
			this.$graphClient
				.query({
					query: searchQuery
				})
				.then(res => res.data)
				.then(data => {
					this.count = data.areas.length + data.resorts.nodes.length;
					this.entries = [
						...data.areas.map(a => {
							return { ...a, destination_type: 'area' };
						}),
						...data.resorts.nodes.map(r => {
							return { ...r, destination_type: 'resort' };
						})
					];
				})
				.catch(_err => {})
				.finally(() => (this.isLoading = false));
		},
		onSubmit(_e) {
			this.submitting = true;
			const selectedDestination = this.entries.find(
				e => e.id === this.destinationId
			);
			if (!selectedDestination) return false;

			const snowAlarm = {
				email: this.email,
				destination_id: selectedDestination.id,
				destination_type: { area: 'SKIRESORT', resort: 'VILLAGE' }[
					selectedDestination.destination_type
				],
				departure_at: this.$dayjs(this.selectedDate)
			};

			return this.$graphClient
				.mutate({
					mutation: gql`
						mutation ($snowalarm: insertSnowAlarmInput) {
							insertSnowAlarm(input: $snowalarm) {
								id
							}
						}
					`,
					errorPolicy: 'all',
					variables: {
						snowalarm: snowAlarm
					}
				})
				.then(({ data, _errors }) => {
					if (!data.insertSnowAlarm.id) {
						this.$toast.error(this.$t('snowalarm-form:submit.error'));
						this.submitting = false;
						return;
					}

					if (data.insertSnowAlarm) {
						this.submitted = true;
					}

					return data.insertSnowAlarm;
				})
				.finally(_e => {
					this.submitting = false;
				})
				.catch(_error => {
					this.$toast.error(this.$t('snowalarm-form:submit.error'));
				});
		},
		allowedDates(val) {
			const now = this.$dayjs();
			let seasonStart = this.$dayjs('2024-12-01').subtract(1, 'days');
			const seasonEnd = this.$dayjs('2025-04-30').add(1, 'days');
			if (now.isAfter(seasonStart)) seasonStart = now;
			return (
				this.$dayjs(val).isBefore(seasonEnd) &&
				this.$dayjs(val).isAfter(seasonStart)
			);
		}
	}
};
</script>

<style lang="sass">
.v-input__append-inner .v-icon
	line-height: 22px !important

.snow-alarm
	.heading .display-1
		line-height: 80px !important
	&--horizontal
		.heading .display-1
			line-height: 60px !important


.snow-alarm--datepicker
	.v-date-picker-header .v-btn
		display: none !important
</style>
